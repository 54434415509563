import { createSlice } from '@reduxjs/toolkit';
import { PersonalStateT } from '../../../types/states';

const initialState: PersonalStateT = {
  loading: false,
  selected: null,
  error: null,
};

const personalSlice = createSlice({
  name: 'personal',
  initialState,
  reducers: {
    personalLoad: (state) => {
      state.loading = true;
      state.selected = null;
    },
    personalLoadSuccess: (state, action) => {
      state.loading = false;
      state.selected = action.payload;
    },
    personalLoadFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { personalLoad, personalLoadFail, personalLoadSuccess } = personalSlice.actions;
export default personalSlice.reducer;
