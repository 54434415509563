import React, { FC, PropsWithChildren } from 'react';
import { Container, Legend } from './styled';
type Props = PropsWithChildren & {
  label?: string;
};
const McGroupBox: FC<Props> = ({ label, children }) => {
  return (
    <Container>
      <Legend>{label}</Legend>
      {children}
    </Container>
  );
};

export default McGroupBox;
