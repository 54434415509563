import { createSlice } from '@reduxjs/toolkit';
import { CategoryStateT } from '../../../types/states';

const initialState: CategoryStateT = {
  loading: false,
  categories: [],
  error: null,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    categoriesLoad: (state) => {
      state.loading = true;
    },
    categoriesLoadSuccess: (state, action) => {
      state.loading = false;
      state.categories = action.payload;
    },
    categoriesLoadFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { categoriesLoad, categoriesLoadFail, categoriesLoadSuccess } = categorySlice.actions;
export default categorySlice.reducer;
