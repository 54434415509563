import MyPuterImg from '../../theme/assets/images/icons/apps/my-puter.png';
import AboutUsImg from '../../theme/assets/images/icons/apps/about-us.png';
import FolderApps from '../../theme/assets/images/icons/folders/folder-apps.png';
import MailWizzard from '../../theme/assets/images/icons/mail-wizard.webp';
import Whatsapp from '../../theme/assets/images/icons/whatsapp.webp';
import { AppProps } from '../../types/props';
import PartnersImg from '../../theme/assets/images/icons/apps/partners.png';
import MinesweeperImg from '../../theme/assets/images/icons/games/minesweeper.png';
import SpiderImg from '../../theme/assets/images/icons/games/spider.webp';
import PaintImg from '../../theme/assets/images/icons/games/paint.webp';
import GreetingImg from '../../theme/assets/images/icons/apps/greetings.png';

export const appsInitialState: AppProps[] = [
  {
    title: 'My Computer',
    type: 'app',
    icon: MyPuterImg,
    x: 0,
    y: 8,
  },
  { title: 'About us', icon: AboutUsImg, type: 'app', x: 0, y: 94 },
  {
    title: 'Projects',
    type: 'app',
    icon: FolderApps,
    x: 0,
    y: 180,
  },
  { title: 'Talk to us', type: 'app', icon: MailWizzard, x: 0, y: 266 },
  {
    title: 'WhatsApp',
    type: 'link',
    action: () => window.open('http://wa.me/972584190296', '_blank', 'noreferrer'),
    icon: Whatsapp,
    x: 0,
    y: 352,
  },
  { title: 'Partners', type: 'app', icon: PartnersImg, x: 0, y: 438 },
  {
    title: 'Minesweeper',
    type: 'app',
    icon: MinesweeperImg,
    x: 0,
    y: 0,
    hide: true,
  },
  {
    title: 'Spider Solitaire',
    type: 'app',
    icon: SpiderImg,
    x: 0,
    y: 0,
    hide: true,
  },
  {
    title: 'Paint',
    type: 'app',
    icon: PaintImg,
    x: 0,
    y: 0,
    hide: true,
  },
  {
    title: 'Greetings',
    type: 'app',
    icon: GreetingImg,
    x: 0,
    y: 0,
    hide: true,
  },
];
