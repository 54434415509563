import React from 'react';
import { SliderItem, Content, Text } from './styled';
import { Accordion } from '../Accordion';
import { Slide, Section } from '../../../../../types/interfaces';

interface SliderContentProps {
  slide: Slide;
  activeSection: number | null;
  onSectionClick: (index: number) => void;
}

const SliderContent: React.FC<SliderContentProps> = ({ slide, activeSection, onSectionClick }) => (
  <SliderItem>
    <Content>
      {slide.content.split('\n').map((line: string, i: number) => (
        <Text key={i}>{line}</Text>
      ))}
      {slide.sections &&
        slide.sections.map((section: Section, sectionIndex: number) => (
          <Accordion
            key={sectionIndex}
            section={section}
            sectionIndex={sectionIndex}
            isOpen={activeSection === sectionIndex}
            onSectionClick={onSectionClick}
          />
        ))}
    </Content>
  </SliderItem>
);

export default SliderContent;
