import { useManualQuery } from 'graphql-hooks';

type ApiModel =
  | 'categories'
  | 'category'
  | 'partners'
  | 'personal'
  | 'project'
  | 'projects'
  | 'projectsByCategory';

const PARTNERS_QUERY = `query ($limit: IntType) {
  allPartners(first: $limit) {
    fullname
    avatar {url}
    roles {name}
    variant
    skills
    leadingProjects {
      name
      socials {
        link
        slug
      }
      slug
    }
    links {
      slug
      link
    }
  }
}`;

const PROJECTS_QUERY = `query ($limit: IntType) {
  allProjects(first: $limit) {
    id
    name
    slug
    description {
      value
    }
    descriptionImage{
      url
    }
    icon {
      url
    }
    examples {
      name
      isPrimary
      remoteUrl
      media {
        url
        video {
          thumbnailUrl
        }
      }
    }
    cases {
      name
    }
    socials {
      slug
      link
    }
    categories {
      name
      slug
    }
  }
}`;
const PROJECT_QUERY = `query ($find: String) {
  project(filter: { slug: { eq: $find}}) {
    id
    name
    slug
    description {
      value
    }
    descriptionImage{
      url
    }
    icon {
      url
    }
    examples {
      name
      isPrimary
      media {
        url
        video {
          thumbnailUrl
        }
      }
    }
    cases {
      name
    }
    socials {
      slug
      link
    }
  }
}`;

const CATEGORIES_QUERY = `{
  allCategories {
    name
    icon {url}
    slug
  }
}`;
const CATEGORY_QUERY = `query ($find: String) {
  category(filter: {slug: { eq: $find }}) {
    id
    name
    icon {url}
    slug
  }
}`;
const PROJECTS_BY_CATEGORY_QUERY = `query ($find: [ItemId]) {
  allProjects(filter: {categories: { allIn: $find} }) {
    name
    icon {url}
    slug
    categories {
      slug
    }
  }
}`;
const PERSONAL_BY_PASS_QUERY = `query ($find: String) {
  personal(filter: { password: { eq: $find } }  ) {
    id
    name
    remotefiles {
      id
      friendlyName
      link
      format
    }
  }
}`;
export const useApi = (model: ApiModel, find?: string, limit = 100) => {
  const queries = {
    categories: CATEGORIES_QUERY,
    category: CATEGORY_QUERY,
    projects: PROJECTS_QUERY,
    projectsByCategory: PROJECTS_BY_CATEGORY_QUERY,
    project: PROJECT_QUERY,
    partners: PARTNERS_QUERY,
    personal: PERSONAL_BY_PASS_QUERY,
  };

  return useManualQuery(queries[model], {
    variables: {
      limit,
      find,
    },
  });
};
