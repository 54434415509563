import React, { useEffect } from 'react';
import { Desktop } from '../../shared/layouts/Desktop';
import { useNavigate } from 'react-router-dom';
import { ProjectsFolder } from '../../shared/components/programs';
import { DesktopIcon } from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/store/reducers/root';
import { closeApp, runApp } from '../../shared/store/reducers/appSlice';
import { useApi } from '../../shared/hooks';
import {
  projectsLoad,
  projectsLoadFail,
  projectsLoadSuccess,
} from '../../shared/store/reducers/data/projectSlice';

const Projects = () => {
  const navigate = useNavigate();
  const app = useSelector((state: RootState) => state.app);
  const project = useSelector((state: RootState) => state.project);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(runApp(app.apps[2]));
    return () => {
      dispatch(closeApp(app.apps[2]));
    };
  }, [app.apps, dispatch]);

  const [fetchData] = useApi('projects');

  useEffect(() => {
    dispatch(projectsLoad());
    fetchData()
      .then((r) => {
        dispatch(projectsLoadSuccess(r.data?.allProjects));
      })
      .catch((e) => {
        dispatch(projectsLoadFail(e));
      });
  }, [dispatch, fetchData]);
  return (
    <Desktop>
      {app.apps.map(({ title, icon, type, action, x, y, hide }) => {
        return !hide && <DesktopIcon {...{ title, icon, type, action, x, y }} key={title} />;
      })}
      {app.opened.length > 0 && project?.list && (
        <ProjectsFolder projects={project.list} onClose={() => navigate('/')} />
      )}
    </Desktop>
  );
};

export default Projects;
