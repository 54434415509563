// Desktop.tsx
import { Toolbar } from 'react95';
import { StyledAppBar, StyledDesk, StyledDesktopFolder } from './styled';
import { NotificationArea, StartMenu, Taskbar, SystemTray } from './components';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { ConnectDropTarget } from 'react-dnd';
import { Screensaver } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/root';
import { endTimer, startTimer } from '../../store/reducers/apps/screensaverSlice';
import { ScreenProvider } from '../../contexts/ScreenContext';

type DesktopProps = {
  children: ReactNode | ReactNode[];
  folderRef?: ConnectDropTarget;
};

const Desktop: FC<DesktopProps> = ({ children, folderRef }) => {
  const dispatch = useDispatch();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', () => dispatch(startTimer()));
    return () => {
      window.removeEventListener('mousemove', () => dispatch(startTimer()));
    };
  }, [dispatch]);

  const { running, caffeine } = useSelector((state: RootState) => state.screensaver);
  useEffect(() => {
    if (!caffeine && !running) {
      const timer = setTimeout(() => {
        dispatch(endTimer());
      }, 600000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [running, caffeine, dispatch]);

  return (
    (!caffeine && running && <Screensaver />) || (
      <ScreenProvider initialWidth={width} initialHeight={height}>
        <StyledDesk id={'desktop-folder'}>
          <StyledDesktopFolder ref={folderRef}>{children}</StyledDesktopFolder>
          <StyledAppBar position={'fixed'}>
            <Toolbar style={{ justifyContent: 'space-between' }}>
              <>
                <StartMenu />
                <Taskbar />
              </>
              <NotificationArea>
                <SystemTray />
              </NotificationArea>
            </Toolbar>
          </StyledAppBar>
        </StyledDesk>
      </ScreenProvider>
    )
  );
};

export default Desktop;
