import React, { useEffect } from 'react';
import { Desktop } from '../../shared/layouts/Desktop';
import { ProjectViewer } from '../../shared/components/programs';
import { useNavigate, useParams } from 'react-router-dom';
import { DesktopIcon, Loader } from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/store/reducers/root';
import {
  findProject,
  projectsLoad,
  projectsLoadFail,
  projectsLoadSuccess,
} from '../../shared/store/reducers/data/projectSlice';
import { useApi } from '../../shared/hooks';

import { closeApp, runApp } from '../../shared/store/reducers/appSlice';

const Project = () => {
  const { project: projectSlug } = useParams();
  const project = useSelector((state: RootState) => state.project);
  const app = useSelector((state: RootState) => state.app);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fetchData] = useApi('projects');

  useEffect(() => {
    if (project.list?.length === 0) {
      dispatch(projectsLoad());
      fetchData()
        .then((r) => {
          dispatch(projectsLoadSuccess(r.data?.allProjects));
        })
        .catch((e) => {
          dispatch(projectsLoadFail(e));
        });
    }
  }, [dispatch, fetchData, project.list?.length]);

  useEffect(() => {
    const projectFounder = async () => {
      try {
        const result = await dispatch(findProject(projectSlug));
        if (result && result.payload && result.payload.selected) {
          document.title = result.payload.selected.name;
        }
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    };
    projectFounder();
  }, [dispatch, project.selected, projectSlug, project.list?.length]);

  useEffect(() => {
    dispatch(runApp(app.apps[2]));
    return () => {
      dispatch(closeApp(app.apps[2]));
    };
  }, [app.apps, dispatch]);

  return (
    <Desktop>
      {app.apps.map(({ title, icon, type, action, x, y, hide }) => {
        return !hide && <DesktopIcon {...{ title, icon, type, action, x, y }} key={title} />;
      })}
      {(project.selected && (
        <ProjectViewer fulldesk={false} data={project.selected} onClose={() => navigate('/')} />
      )) || <Loader title={project.selected?.name ?? ''} onClose={() => navigate('/')} />}
    </Desktop>
  );
};

export default Project;
