import React, { FC, ReactNode } from 'react';
import { FoldersScrollView, StyledFrame, Wrapper } from './styled';
type Props = { children: ReactNode | ReactNode[] };
const FileExplorer: FC<Props> = ({ children }) => {
  return (
    <FoldersScrollView>
      <StyledFrame>
        <Wrapper>{children}</Wrapper>
      </StyledFrame>
    </FoldersScrollView>
  );
};

export default FileExplorer;
