import styled from 'styled-components';
import { AppWindow } from '../../../../AppWindow';

export const StyledAppWindow = styled(AppWindow)`
  width: max-content;
  height: max-content;
  address {
    padding: 1rem;
    p {
      margin: 0.5rem 0;
    }
  }
  a {
    color: #0000ff;
    text-decoration: underline;
  }
`;
