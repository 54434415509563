import React, { FC } from 'react';
import { Folder, ReadmeFile } from '../../../../ui';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../../../../../router/routes';

type Props = {
  onClick: (value?: string) => void;
  folders?: { name: string; icon: string; slug?: string }[];
  loading: boolean;
};
const DriveC: FC<Props> = ({ onClick, folders, loading }) => {
  const navigate = useNavigate();
  return (
    <>
      {!loading &&
        folders &&
        folders.map(({ name, icon, slug }) => {
          return (
            <Folder
              key={name}
              onClick={() => {
                onClick(name);
                if (slug) {
                  navigate('/' + Paths.myComputerDriveCProjects.replace(':slug', slug));
                }
              }}
              icon={icon}
            >
              {name}
            </Folder>
          );
        })}
      <ReadmeFile />
    </>
  );
};

export default DriveC;
