import React from 'react';
import { BootStringM, BootStringS, Container, Cursor, DiffString, Logo } from './styled';

const BootScreen = () => {
  const showAnim = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };
  const blinkingAnimation = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <Container>
      <Logo
        animate="show"
        initial="hidden"
        variants={showAnim}
        transition={{ duration: 0, delay: 0.1 }}
      >
        {`
    M         M   UUUU   UUUU   DDDD    UUUU   UUUU   .   M         M   EEEEEEE
    MM       MM   UUUU   UUUU   DD DD   UUUU   UUUU   .   MM       MM   EEEEEEE
    M M     M M   UUUU   UUUU   DD  DD  UUUU   UUUU   .   M M     M M   EE
    M  M   M  M   UUUU   UUUU   DD   DD UUUU   UUUU   .   M  M   M  M   EE
    M   M M   M   UUUU   UUUU   DD   DD UUUU   UUUU   .   M   M M   M   EEEEE
    M    M    M   UUUU   UUUU   DD   DD UUUU   UUUU   .   M    M    M   EEEEE
    M         M   UUUU   UUUU   DD  DD  UUUU   UUUU   .   M         M   EE
    M         M   UUUU   UUUU   DD DD   UUUU   UUUU   .   M         M   EE
    M         M   UUUU   UUUU   DDDD    UUUU   UUUU   .   M         M   EEEEEEE
    M         M    UUUUUUUUUU   DDD      UUUUUUUUUU   .   M         M   EEEEEEE
        `}
      </Logo>
      <BootStringS
        animate="show"
        initial="hidden"
        variants={showAnim}
        transition={{ duration: 0, delay: 0.3 }}
      >
        <DiffString>Site loading start</DiffString>
      </BootStringS>
      <BootStringM
        animate="show"
        initial="hidden"
        variants={showAnim}
        transition={{ duration: 0, delay: 0.7 }}
      >
        System language: <BootStringS>{navigator?.language}</BootStringS>
      </BootStringM>
      <BootStringM
        animate="show"
        initial="hidden"
        variants={showAnim}
        transition={{ duration: 0, delay: 0.8 }}
      >
        User Agent: <BootStringS>{navigator?.userAgent}</BootStringS>
      </BootStringM>
      <BootStringM
        animate="show"
        initial="hidden"
        variants={showAnim}
        transition={{ duration: 0, delay: 0.8 }}
      >
        Hardware Concurrency: <BootStringS>{navigator?.hardwareConcurrency}</BootStringS>
      </BootStringM>
      <BootStringS
        animate="show"
        initial="hidden"
        variants={showAnim}
        transition={{ duration: 0, delay: 0.8 }}
      >
        Done
      </BootStringS>
      <BootStringS
        animate="show"
        initial="hidden"
        variants={showAnim}
        transition={{ duration: 0, delay: 0.9 }}
      >
        Loading Kernel System BIOS Memory
      </BootStringS>
      <BootStringS
        animate="show"
        initial="hidden"
        variants={showAnim}
        transition={{ duration: 0, delay: 0.91 }}
      >
        Boot Initializing Services Network
      </BootStringS>
      <BootStringS
        animate="show"
        initial="hidden"
        variants={showAnim}
        transition={{ duration: 0, delay: 0.92 }}
      >
        Components Kernel Loading Startup
      </BootStringS>
      <BootStringS
        animate="show"
        initial="hidden"
        variants={showAnim}
        transition={{ duration: 0, delay: 1 }}
      >
        BIOS Configuring Memory Drivers
      </BootStringS>
      <BootStringS
        animate="show"
        initial="hidden"
        variants={showAnim}
        transition={{ duration: 0, delay: 1 }}
      >
        Authentication System Loading Services
      </BootStringS>
      <Cursor
        initial="hidden"
        transition={{ repeat: Infinity, duration: 0.2, delay: 1 }}
        animate="visible"
        variants={blinkingAnimation}
      />
    </Container>
  );
};

export default BootScreen;
