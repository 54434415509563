import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url(https://d2kq0urxkarztv.cloudfront.net/63985d153db1670022651b5c/4101733/image-941dc888-9cba-4d53-8044-a486c42ae5bf.png?e=webp&nll=true);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GradientSpinner = styled.span`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(
    90deg,
    rgba(65, 126, 188, 1) 0%,
    rgba(65, 126, 188, 1) 25%,
    rgba(167, 182, 193, 1) 50%,
    rgba(167, 182, 193, 1) 75%,
    rgba(65, 126, 188, 1) 100%
  );
  background-size: 300% 300%;

  -webkit-animation: gradient 2s linear infinite;
  -moz-animation: gradient 2s linear infinite;
  animation: gradient 2s linear infinite;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const TextContainer = styled.span`
  font-weight: bolder;
  font-size: 10vh;
  i {
    font-style: italic;
    font-weight: 600;
  }
  b {
    color: navy;
    font-weight: 900;
  }
`;
