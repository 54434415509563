import React, { FC } from 'react';
import { IExample } from '../../../../../types/data';
import { Content, PreviewContainer, Img } from './styled';
import { Videoplayer } from './Videoplayer';

const Preview: FC<IExample> = ({ onClick, type, src, name }) => {
  const content = (type === 'image' && <Img src={src} alt={name} />) || (
    <Videoplayer {...{ url: src, name }} />
  );
  return (
    <PreviewContainer onClick={onClick}>
      <Content>{content}</Content>
    </PreviewContainer>
  );
};

export default Preview;
