export enum Paths {
  desktop = '',
  minesweeper = 'minesweeper',
  solitaire = 'solitaire',
  greetings = 'greetings',
  paint = 'paint',
  myComputer = 'my-computer',
  myComputerDriveC = 'my-computer/drive-c',
  myComputerDriveCProjects = 'my-computer/drive-c/:slug',
  myComputerDriveCProjectsOpen = 'my-computer/drive-c/:category/:project',
  partners = 'partners',
  projects = 'projects',
  projectViewer = 'project-viewer/:project',
  talkUs = 'talk-to-us',
  aboutUs = 'about-us',
}
