import React, { FC, Ref } from 'react';
import { Button } from 'react95';
import {
  ButtonWrapper,
  StyledCheckbox,
  StyledFieldset,
  StyledForm,
  StyledSelect,
  StyledTextInput,
} from './styled';
import { useScopedTranslation } from '../../../../../hooks';

export type Props = {
  handleSubmit: () => void;
  submitRef?: Ref<HTMLButtonElement>;
};
const ContactForm: FC<Props> = ({ handleSubmit, submitRef }) => {
  const FORM_ENDPOINT = 'https://public.herotofu.com/v1/40c37540-fa5c-11ed-9ea5-6d659508bc55';
  const options = ['Under 300,000₪', '300,000₪ — 700,000₪', '700,000₪ +'];
  const { t } = useScopedTranslation('talk_to_us.form');

  return (
    <StyledForm action={FORM_ENDPOINT} onSubmit={handleSubmit} method="POST" target="_blank">
      <StyledFieldset>
        <StyledTextInput
          type="text"
          placeholder={t('input.firstName')}
          name={t('input.firstName')}
          required
          fullWidth
        />
        <StyledTextInput
          type="text"
          placeholder={t('input.lastName')}
          name={t('input.lastName')}
          required
          fullWidth
        />
        <StyledTextInput
          type="text"
          placeholder={t('input.company')}
          name={t('input.company')}
          fullWidth
        />
        <StyledTextInput
          type="email"
          placeholder={t('input.email')}
          name={t('input.email')}
          required
          fullWidth
        />
      </StyledFieldset>
      <StyledFieldset>
        <StyledCheckbox
          label={t('checkbox.marketing')}
          name={t('checkbox.marketing')}
          value={'Yes!'}
        />
        <StyledCheckbox label={t('checkbox.content')} name={t('checkbox.content')} value={'Yes!'} />
        <StyledCheckbox label={t('checkbox.website')} name={t('checkbox.website')} value={'Yes!'} />
      </StyledFieldset>
      <StyledFieldset>
        <StyledSelect
          placeholder={t('select.budget')}
          name={t('select.budget')}
          options={[
            ...options.map((option) => ({ label: option, value: option })),
            { label: t('select.budget'), value: '' },
          ]}
          defaultValue={''}
          width="100%"
        />
        <StyledTextInput
          placeholder={t('input.message')}
          name={t('input.message')}
          multiline
          rows={5}
          fullWidth
        />
      </StyledFieldset>
      <ButtonWrapper style={{ width: '100%' }}>
        <Button ref={submitRef} type="submit">
          {t('submit')}
        </Button>
      </ButtonWrapper>
    </StyledForm>
  );
};

export default ContactForm;
