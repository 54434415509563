import AboutImg from '../../shared/theme/assets/images/about-us/info.webp';
import ConThinkImg from '../../shared/theme/assets/images/about-us/con-think.webp';
import ErrorsImg from '../../shared/theme/assets/images/about-us/errors.webp';
import whwImg from '../../shared/theme/assets/images/about-us/whw.webp';
import brandingImg from '../../shared/theme/assets/images/about-us/branding.webp';
import strategyImg from '../../shared/theme/assets/images/about-us/strategy.webp';
import productionImg from '../../shared/theme/assets/images/about-us/production.webp';
import webDevImg from '../../shared/theme/assets/images/about-us/web.svg';
import contentImg from '../../shared/theme/assets/images/about-us/content.webp';
import automation from '../../shared/theme/assets/images/about-us/automation.svg';
import designImg from '../../shared/theme/assets/images/about-us/design.webp';
import ppcImg from '../../shared/theme/assets/images/about-us/ppc.webp';

export const images = {
  AboutImg: {
    src: AboutImg,
    alt: 'info',
    path: 'social-media-marketing',
  },
  ConThinkImg: {
    src: ConThinkImg,
    alt: 'thinking',
    path: 'social-media-marketing',
  },
  ErrorsImg: {
    src: ErrorsImg,
    alt: 'error',
    path: 'social-media-marketing',
  },
  whwImg: {
    src: whwImg,
    alt: 'work',
    path: 'work',
  },
  brandingImg: {
    src: brandingImg,
    alt: 'branding',
    path: 'branding-strategy',
  },
  strategyImg: {
    src: strategyImg,
    alt: 'strategy',
    path: 'branding-strategy',
  },
  productionImg: {
    src: productionImg,
    alt: 'production',
    path: 'video-production',
  },
  marketingImg: {
    src: webDevImg,
    alt: 'web-development',
    path: 'social-media-marketing',
  },
  contentImg: {
    src: contentImg,
    alt: 'content',
    path: 'video-production',
  },
  automationImg: {
    src: automation,
    alt: 'automation',
    path: 'automatiozation',
  },
  designImg: {
    src: designImg,
    alt: 'design',
    path: 'social-media-marketing',
  },
  ppcImg: {
    src: ppcImg,
    alt: 'ppc',
    path: 'cultural-hubs',
  },
};
