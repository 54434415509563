import styled from 'styled-components';
import { GroupBox } from 'react95';

export const IconDescription = styled(GroupBox)`
  font-size: 1rem;
  text-align: center;
  padding: 10px;
  color: #000;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const StyledGroupBox = styled(GroupBox)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const IconContainer = styled(GroupBox)`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 20px;
  padding: 5px;
  overflow: auto;
  width: 100%;
`;

export const SocialIconContainer = styled(GroupBox)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
  padding: 5px;
  width: 100%;
`;
