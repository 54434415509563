import React from 'react';
import { TitleContainer, PreviousArrow, NextArrow, Title } from './styled';

const ArrowNavigation: React.FC<{ onPrevious: () => void; onNext: () => void; title: string }> = ({
  onPrevious,
  onNext,
  title,
}) => (
  <TitleContainer>
    <PreviousArrow onClick={onPrevious}>&#10094;</PreviousArrow>
    <Title>{title}</Title>
    <NextArrow onClick={onNext}>&#10095;</NextArrow>
  </TitleContainer>
);

export default ArrowNavigation;
