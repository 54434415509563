import React, { FC, forwardRef } from 'react';
import { Icon } from '../Icon';
import { SocialMedia } from '../../../types/data';
import fbIcon from '../../../theme/assets/images/icons/social/fb.jpeg';
import instIcon from '../../../theme/assets/images/icons/social/inst.png';
import tgIcon from '../../../theme/assets/images/icons/social/tg.png';
import linkdIcon from '../../../theme/assets/images/icons/social/linkd.png';
import twitterIcon from '../../../theme/assets/images/icons/social/twr.png';
import tiktokIcon from '../../../theme/assets/images/icons/social/tkt.png';
import websiteIcon from '../../../theme/assets/images/icons/social/website.png';
import whatsappIcon from '../../../theme/assets/images/icons/social/wta.png';
import youtubeIcon from '../../../theme/assets/images/icons/social/yt.png';
import styled from 'styled-components';

type Props = {
  social: SocialMedia;
  onClick?: () => void;
  size?: number;
  link?: string;
  text?: boolean;
  className?: string;
};

const StyledA = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const icon = {
  discord: youtubeIcon,
  facebook: fbIcon,
  instagram: instIcon,
  linkedin: linkdIcon,
  telegram: tgIcon,
  tiktok: tiktokIcon,
  twitter: twitterIcon,
  website: websiteIcon,
  whatsapp: whatsappIcon,
  youtube: youtubeIcon,
};
const SocialIcon: FC<Props> = forwardRef<HTMLAnchorElement, Props>(
  ({ social, size, onClick, link, text, className }, ref) => {
    const socialNames: string[] = Object.keys(icon);
    return (
      <StyledA className={className} href={link} target={'_blank'} rel="noreferrer" ref={ref}>
        {(!text && <Icon src={icon[social]} alt={social} {...{ onClick, size }} />) || (
          <u>{socialNames.find((item) => social === item)}</u>
        )}
      </StyledA>
    );
  },
);

export default SocialIcon;
