import { createSlice } from '@reduxjs/toolkit';
import { ProjectStateT } from '../../../types/states';
import { arrFirst } from '../../../utils/arrFirst';

const initialState: ProjectStateT = {
  loading: false,
  list: [],
  selected: null,
  error: null,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    projectsLoad: (state) => {
      state.loading = true;
    },
    projectsLoadSuccess: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    projectsLoadFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    findProject: (state, action) => {
      state.loading = false;
      if (state.list && state.list.length > 0) {
        const result = arrFirst(state.list, action.payload);
        if (result) {
          state.selected = result;
        } else {
          state.selected = null;
        }
      }
    },
  },
});

export const { projectsLoad, projectsLoadFail, projectsLoadSuccess, findProject } =
  projectSlice.actions;
export default projectSlice.reducer;
