import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border: 1px solid white;
  padding: 10px;
  margin: 0 5px;
  @media (max-width: 768px) {
    width: initial;
    height: 100%;
    margin: 5px;
  }
`;

export const Legend = styled.legend`
  width: max-content;
  position: relative;
  top: -16px;
  left: 50%;
  background-color: blue;
  padding: 0 0.5em;
  color: white;
  font-family: 'DOS_VGA_437', monospace;
`;
