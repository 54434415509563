import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers/root';
import { Folder } from '../../../../ui';
import { iconByFormat } from '../../../../../utils/iconByFormat';

const PersonalFiles: FC = () => {
  const personal = useSelector((state: RootState) => state.personal);
  return (
    <>
      {personal?.selected?.remotefiles?.map(({ friendlyName, link, format }) => (
        <Folder
          key={link}
          onClick={() => {
            window.open(link, '_blank');
          }}
          variant={'drive'}
          icon={iconByFormat(format)}
        >
          {friendlyName}
        </Folder>
      ))}
    </>
  );
};

export default PersonalFiles;
