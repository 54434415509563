import styled from 'styled-components';
import { AppBar } from 'react95';
import underConstruction from '../../theme/assets/images/home-logo.webp';
export const StyledDesk = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.desktopBackground};
  width: 100%;
  height: 100vh;
`;

export const StyledAppBar = styled(AppBar)`
  top: initial;
  bottom: 0;
  z-index: 1000;
  border-bottom: none;
  border-left: none;
  border-right: none;
`;

export const StyledDesktopFolder = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-image: url(${underConstruction});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10%;
  flex-direction: column;
`;
