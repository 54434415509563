import React from 'react';
import { TooltipContainer, CloseButton, TextContainer, Title } from './styled';
import { TooltipContent } from '../../../../../utils/tooltipContent';

interface TooltipProps {
  visible: boolean;
  onClose: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({ visible, onClose }) => {
  return (
    <TooltipContainer visible={visible}>
      <Title>
        {TooltipContent.title}
        <CloseButton onClick={onClose}>×</CloseButton>
      </Title>
      <TextContainer>{TooltipContent.text}</TextContainer>
    </TooltipContainer>
  );
};

export default Tooltip;
