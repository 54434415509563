import React, { FC, useState, useEffect } from 'react';
import { Select } from 'react95';
import { SelectOption } from 'react95/dist/Select/Select.types';
import { AccessRequest } from '../AccessRequest';
import { useAccessRequest } from '../../../../../hooks/api/useAccessRequest';
import { Modal } from '../../../../ui';

type IPath = 'root' | 'c' | 'category' | 'personal-files';

interface PathSelectorProps {
  options: SelectOption<string>[];
  currentPath: IPath | string;
  onPathChange: (newPath: IPath | string) => void;
}

const PathSelector: FC<PathSelectorProps> = ({ options, currentPath, onPathChange }) => {
  const { showAccessRequest, setShowAccessRequest, pass, setPass, denied, requestAccess } =
    useAccessRequest();
  const [selectedPath, setSelectedPath] = useState<IPath | string>(currentPath);

  useEffect(() => {
    setSelectedPath(currentPath);
  }, [currentPath]);

  const handleChange = (value: SelectOption<string>) => {
    if (value.value === 'personal-files') {
      setSelectedPath('');
      setShowAccessRequest(true);
    } else {
      onPathChange(value.value);
      setSelectedPath(value.value);
    }
  };

  const handleModalClose = () => {
    setShowAccessRequest(false);
    setSelectedPath(currentPath);
  };

  const handlePass = () => {
    requestAccess(() => {
      onPathChange('personal-files');
      setSelectedPath('personal-files');
      setShowAccessRequest(false);
    });
  };

  return (
    <>
      <Select
        width={240}
        value={selectedPath}
        onChange={(value) => handleChange(value)}
        options={options}
      />
      <Modal show={showAccessRequest}>
        <AccessRequest
          pass={pass}
          setPass={setPass}
          denied={denied}
          onPass={handlePass}
          onClose={handleModalClose}
        />
      </Modal>
    </>
  );
};

export default PathSelector;
