import React, { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

type Props = PropsWithChildren & {
  show: boolean;
};

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: hue-rotate(28deg) invert(10%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal: FC<Props> = ({ children, show }) => {
  return createPortal(<>{show && <StyledWrapper>{children}</StyledWrapper>}</>, document.body);
};

export default Modal;
