import React from 'react';
import { StyledIcon, Task, TaskArea, TaskText } from './styled';
import { AppProps } from '../../../../types/props';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/root';

const Taskbar = () => {
  const { opened } = useSelector((state: RootState) => state.app);
  return (
    <TaskArea>
      {opened.map(({ title, icon }: AppProps) => (
        <Task key={title}>
          <StyledIcon src={icon} />
          <TaskText>{title}</TaskText>
        </Task>
      ))}
    </TaskArea>
  );
};

export default Taskbar;
