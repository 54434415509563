import styled from 'styled-components';
import { GroupBox } from 'react95';

export const DescriptionList = styled(GroupBox)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DescriptionTextContainer = styled.div`
  flex: 1;
  padding-right: 20px;
  letter-spacing: 0.1em;
  font-size: 14px;
  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 16px;
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: bold;
  }

  ul,
  ol {
    margin-left: 20px;
    margin-bottom: 16px;
  }

  li {
    margin-bottom: 8px;
  }

  a {
    color: black;
    text-decoration: underline;
  }

  strong,
  b {
    font-weight: bold;
  }

  em,
  i {
    font-style: italic;
  }
`;

export const DescriptionImageContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
`;

export const DescriptionImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
`;
