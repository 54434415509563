import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import { Tooltip } from 'react95';

const Clock = () => {
  const [datetime, setDatetime] = useState(dayjs());
  useEffect(() => {
    const timer = setInterval(() => {
      setDatetime(dayjs());
    }, 60000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Tooltip text={datetime.format('DD.MM.YYYY')} enterDelay={1000} leaveDelay={500}>
      <div>{datetime.format('h:mm')}</div>
    </Tooltip>
  );
};

export default Clock;
