import React, { useEffect, useState } from 'react';
import { Container, Glitch, GradientSpinner, Logo, TextContainer } from './styled';
import { Icon } from '../../../ui';
import startLogo from '../../../../theme/assets/images/start-logo.webp';

const GlitchSplashScreen = () => {
  const [filter, setFilter] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setFilter(true), 600);
    return () => {
      setFilter(false);
    };
  }, []);
  return (
    <Glitch filter={filter}>
      <Container>
        <Logo>
          <Icon src={startLogo} />
          <TextContainer>
            <i>MUDU</i>.<b>ME</b>
          </TextContainer>
        </Logo>
        <GradientSpinner />
      </Container>
    </Glitch>
  );
};

export default GlitchSplashScreen;
