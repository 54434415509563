import React, { FC } from 'react';
import { StyledContainer, StyledIconContainer, StyledTitle } from './styled';
import { Icon } from './../../../../components/ui/Icon';
import Shortcut from '../../../../theme/assets/images/icons/shortcut.webp';

interface WelcomeIconProps {
  title: string;
  icon: string;
  type?: 'link' | 'app';
  action?: () => void;
}

const WelcomeIcon: FC<WelcomeIconProps> = ({ title, icon, type, action }) => {
  return (
    <StyledContainer>
      <StyledIconContainer onClick={action}>
        {type === 'link' && <img src={Shortcut} alt="shortcut" />}
        <Icon src={icon} alt={title} />
      </StyledIconContainer>
      <StyledTitle>{title}</StyledTitle>
    </StyledContainer>
  );
};

export default WelcomeIcon;
