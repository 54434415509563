import React, { FC, PropsWithChildren } from 'react';
import { emojis } from '../../../../../utils/emoji';
import { Container, Label, Number } from './styled';

type Props = PropsWithChildren & {
  onClick?: () => void;
  number: number;
};

const getRandomEmoji = (): string => {
  const randomEmoji = Math.floor(Math.random() * emojis.length);
  return emojis[randomEmoji];
};

const NumberButton: FC<Props> = ({ onClick, number }) => {
  const getEmoji = getRandomEmoji();
  return (
    <Container onClick={onClick}>
      <Number>{number}</Number>
      <Label>{getEmoji}</Label>
    </Container>
  );
};

export default NumberButton;
