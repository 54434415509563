import React, { FC, PropsWithChildren } from 'react';
import { Bottom, Console, Container, Content, Row, Top, TopMenu } from './styled';
import { NumberButton } from '../NumberButton';

type Props = {
  selectedTab: string;
  onSelectTab: (tab: string) => void;
};

const BlueFrame: FC<PropsWithChildren<Props>> = ({ selectedTab, onSelectTab, children }) => {
  const handleMenuClick = (menu: string) => {
    onSelectTab(menu);
  };

  const topMenu = [
    { label: 'STAFF', value: 'staff' },
    { label: 'PARTNERS', value: 'partner' },
    { label: 'COLLABORATIONS', value: 'collaborator' },
  ];

  return (
    <Container>
      <Top>
        {topMenu.map((item) => (
          <TopMenu
            key={item.value}
            onClick={() => handleMenuClick(item.value)}
            active={selectedTab === item.value}
          >
            {item.label}
          </TopMenu>
        ))}
      </Top>
      <Content>{children}</Content>
      <Bottom>
        <Console>${'C:/Desktop/Partners/' + selectedTab + '.exe'}</Console>
        <Row>
          {[...Array(10)].map((_, index) => (
            <NumberButton key={index} number={index} />
          ))}
        </Row>
      </Bottom>
    </Container>
  );
};

export default BlueFrame;
