import React, { useEffect, useRef } from 'react';
import { Section } from '../../../../../types/interfaces';
import {
  AccordionSection,
  AccordionHeader,
  AccordionContent,
  AccordionIndicator,
  Subtitle,
  Text,
} from './styled';

interface AccordionProps {
  section: Section;
  sectionIndex: number;
  isOpen: boolean;
  onSectionClick: (index: number) => void;
}

const Accordion: React.FC<AccordionProps> = ({ section, sectionIndex, isOpen, onSectionClick }) => {
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [isOpen]);

  return (
    <AccordionSection ref={sectionRef}>
      <AccordionHeader onClick={() => onSectionClick(sectionIndex)} isOpen={isOpen}>
        {section.heading}
        <AccordionIndicator isOpen={isOpen}>{isOpen ? '▼' : '▼'}</AccordionIndicator>
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>
        {section.items.map((item, itemIndex) => (
          <React.Fragment key={itemIndex}>
            <Subtitle>{item.title}</Subtitle>
            <Text>{item.text}</Text>
          </React.Fragment>
        ))}
      </AccordionContent>
    </AccordionSection>
  );
};

export default Accordion;
