import React, { FC } from 'react';

import styled from 'styled-components';

export const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;

type Props = {
  title: string;
  src: string;
};

const IFrame: FC<Props> = (props) => {
  return <StyledIFrame {...props} />;
};

export default IFrame;
