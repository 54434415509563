import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../../../../router/routes';
import { IconContainer, IconDescription, StyledGroupBox, SocialIconContainer } from './styled';
import { WelcomeIcons } from '../WelcomeIcons';
import { SocialIcon } from '../../../ui';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/root';

const RightPaneSection: React.FC = () => {
  const navigate = useNavigate();

  const actionCallback = (name: string) => {
    switch (name) {
      case 'My Computer':
        return () => {
          navigate('/my-computer');
        };
      case 'Projects':
        return () => {
          navigate(`/${Paths.projects}`);
        };
      case 'Talk to us':
        return () => {
          navigate(`/${Paths.talkUs}`);
        };
      case 'About us':
        return () => {
          navigate(`/${Paths.aboutUs}`);
        };
      case 'Partners':
        return () => {
          navigate(`/${Paths.partners}`);
        };
      default:
        return () => {
          console.warn(`No navigation action defined for ${name}`);
        };
    }
  };

  const app = useSelector((state: RootState) => state.app);

  return (
    <>
      <IconDescription>Please feel free to explore.</IconDescription>
      <StyledGroupBox label={'Select the required action'}>
        <React.Fragment>
          Here you can find various shortcuts to different sections of our website. Click on any
          icon to navigate to the respective section.
        </React.Fragment>
        <IconContainer>
          {app.apps.map(({ title, icon, type, action, hide }) => {
            const updateAction = (action && action) || actionCallback(title);
            const iconSrc = icon || '';
            return (
              !hide && (
                <WelcomeIcons key={title} title={title} icon={iconSrc} action={updateAction} />
              )
            );
          })}
        </IconContainer>
      </StyledGroupBox>
      <StyledGroupBox label={'Follow us on social media'}>
        <React.Fragment>
          Connect with us through our social media channels to stay updated with our latest news and
          updates.
        </React.Fragment>
        <SocialIconContainer>
          <SocialIcon social="facebook" link="https://www.facebook.com" />
          <SocialIcon social="instagram" link="https://www.instagram.com" />
          <SocialIcon social="telegram" link="https://www.telegram.org" />
          <SocialIcon social="linkedin" link="https://www.linkedin.com" />
          <SocialIcon social="tiktok" link="https://www.tiktok.com" />
          <SocialIcon social="whatsapp" link="https://www.whatsapp.com" />
          <SocialIcon social="youtube" link="https://www.youtube.com" />
        </SocialIconContainer>
      </StyledGroupBox>
    </>
  );
};

export default RightPaneSection;
