import { Example, Project } from '../../../types/data';

export const getFirstExample = (examples: Example[]): Example => {
  const primary = examples.find((item) => item.isPrimary);
  const firstVideo = examples.find((item) => item.type === 'video');
  const first = examples[0];
  return (primary && primary) || (firstVideo && firstVideo) || first;
};

export const findNearElements = (items?: Project[], current?: string) => {
  if (!items) return null;
  const currentIndex = items.findIndex((item) => item.slug === current);
  const prev = currentIndex - 1;
  const next = currentIndex + 1;
  return { prev: items[prev], next: items[next] };
};
