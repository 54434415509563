import React, { FC } from 'react';
import appIcon from '../../../../../theme/assets/images/icons/chm.png';
import { StyledAppWindow } from './styled';
import { CompanyAddress } from '../../../../CompanyAddress';

type Props = { onClose: () => void; x?: number; y?: number };

const Help: FC<Props> = ({ onClose, x, y }) => {
  return (
    <StyledAppWindow isdraggable={false} iconScr={appIcon} title={'Help'} {...{ onClose, x, y }}>
      <CompanyAddress />
    </StyledAppWindow>
  );
};

export default Help;
