import React, { useCallback, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import {
  AnimaContainer,
  CloudsContainer,
  EndBlock,
  EndColumnOne,
  EndRowOne,
  FistBlock,
  MainContainer,
  MiddleBlock,
  TextContainer,
  StyledMotionImage,
  ImgTwo,
  ImgOne,
  ImgThree,
  ImgFour,
} from './styled';
import { images } from '../../../../shared/utils/aboutUsImages';
import { Button } from 'react95';
import {
  CloseButton,
  StyledActions,
  StyledTitleContainer,
  StyledWindowHeader,
} from '../../../../shared/components/AppWindow/styled';
import { CompanyAddress } from '../../../../shared';
import { useNavigate } from 'react-router-dom';

const AdScreen = ({ onClose }: { onClose: () => void }) => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({ container: containerRef });
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 360]);
  const navigate = useNavigate();

  const greetingsPath = useCallback(
    (slug: string) => {
      navigate(`/my-computer/drive-c/${slug}`);
    },
    [navigate],
  );

  return (
    <MainContainer ref={containerRef}>
      <StyledWindowHeader>
        <StyledTitleContainer>{'About us'}</StyledTitleContainer>
        <StyledActions>
          <Button onClick={onClose}>
            <CloseButton />
          </Button>
        </StyledActions>
      </StyledWindowHeader>
      <CloudsContainer>
        <AnimaContainer>
          <EndBlock>
            <ImgFour
              transition={{ delay: 0.05, duration: 0.25 }}
              initial={{ opacity: 0, scale: 0, y: 200 }}
              whileInView={{ opacity: 1, scale: 1.2, y: -10 }}
              viewport={{ once: true, amount: 0.5 }}
              src={images.whwImg.src}
              style={{ cursor: 'pointer' }}
              alt={images.whwImg.alt}
            />

            <EndRowOne>
              <motion.img
                transition={{ delay: 0.05, duration: 0.5 }}
                initial={{ opacity: 0, scale: 0, y: 200 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                whileHover={{ rotate: 5 }}
                viewport={{ once: true }}
                height={205}
                src={images.brandingImg.src}
                style={{ cursor: 'pointer' }}
                alt={images.brandingImg.alt}
                onClick={() => greetingsPath(images.brandingImg.path)}
              />

              <EndColumnOne>
                <motion.img
                  transition={{ delay: 0.15, duration: 0.5 }}
                  initial={{ x: 300 }}
                  whileHover={{ rotate: -5 }}
                  whileInView={{ x: 0 }}
                  width={'330'}
                  style={{ cursor: 'pointer' }}
                  src={images.strategyImg.src}
                  alt={images.strategyImg.alt}
                  onClick={() => greetingsPath(images.strategyImg.path)}
                />
                <motion.img
                  transition={{ delay: 0.15, duration: 0.5 }}
                  whileHover={{ scale: 0.8 }}
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1.5 }}
                  width={'256'}
                  style={{ cursor: 'pointer' }}
                  src={images.productionImg.src}
                  alt={images.productionImg.alt}
                  onClick={() => greetingsPath(images.productionImg.path)}
                />
              </EndColumnOne>
            </EndRowOne>
            <EndRowOne>
              <EndColumnOne>
                <EndRowOne>
                  <StyledMotionImage
                    transition={{ delay: 0.45, duration: 0.5 }}
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    whileHover={{ rotate: -10 }}
                    width={'182'}
                    style={{ cursor: 'pointer' }}
                    src={images.marketingImg.src}
                    alt={images.marketingImg.alt}
                    onClick={() => greetingsPath(images.marketingImg.path)}
                  />
                  <StyledMotionImage
                    transition={{ delay: 0.25, duration: 0.5 }}
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    whileHover={{ scale: 1.2 }}
                    width={'174'}
                    src={images.contentImg.src}
                    style={{ cursor: 'pointer' }}
                    alt={images.contentImg.alt}
                    onClick={() => greetingsPath(images.contentImg.path)}
                  />
                </EndRowOne>
                <motion.img
                  transition={{ delay: 0.35, duration: 0.5 }}
                  initial={{ opacity: 0 }}
                  width={'400'}
                  whileInView={{ opacity: 1 }}
                  src={images.automationImg.src}
                  style={{ cursor: 'pointer' }}
                  whileHover={{ rotate: 10 }}
                  alt={images.automationImg.alt}
                  onClick={() => greetingsPath(images.automationImg.path)}
                />
              </EndColumnOne>
              <EndColumnOne>
                <motion.img
                  transition={{ delay: 0.5, duration: 0.5 }}
                  initial={{ rotate: 180, opacity: 0 }}
                  whileInView={{ rotate: 0, opacity: 1 }}
                  width={'152'}
                  src={images.designImg.src}
                  style={{ cursor: 'pointer' }}
                  alt={images.designImg.alt}
                  whileHover={{ rotate: 15 }}
                  onClick={() => greetingsPath(images.designImg.path)}
                />
                <motion.img
                  transition={{ delay: 0.45, duration: 0.75 }}
                  initial={{ rotate: 180, opacity: 0, y: 200 }}
                  whileInView={{ rotate: 0, opacity: 1, y: 0 }}
                  width={'171'}
                  src={images.ppcImg.src}
                  alt={images.ppcImg.alt}
                  style={{ cursor: 'pointer' }}
                  whileHover={{ scale: 1.2 }}
                  onClick={() => greetingsPath(images.ppcImg.path)}
                />
              </EndColumnOne>
            </EndRowOne>
          </EndBlock>
          <MiddleBlock>
            <ImgTwo
              transition={{ delay: 0.5, duration: 0.25 }}
              initial={{ y: -300, scale: 0 }}
              whileHover={{ scale: 1.2 }}
              whileInView={{ scale: 1, x: 50 }}
              src={images.ConThinkImg.src}
              style={{ cursor: 'pointer' }}
              alt={images.ConThinkImg.alt}
            />
            <ImgThree
              transition={{ delay: 0.15, duration: 0.25 }}
              initial={{ x: 330 }}
              whileInView={{ x: 0 }}
              style={{ cursor: 'pointer' }}
              src={images.ErrorsImg.src}
              alt={images.ErrorsImg.alt}
            />
          </MiddleBlock>
          <FistBlock>
            <ImgOne
              transition={{ delay: 0.15, duration: 0.25 }}
              initial={{ scale: 0 }}
              animate={{ scale: 1.2 }}
              src={images.AboutImg.src}
              alt={images.AboutImg.alt}
              onClick={() => greetingsPath(images.AboutImg.path)}
              style={{ rotate: rotate }}
            />
          </FistBlock>
        </AnimaContainer>
        <TextContainer>
          <CompanyAddress />
        </TextContainer>
      </CloudsContainer>
    </MainContainer>
  );
};

export default AdScreen;
