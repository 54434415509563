import React, { useEffect } from 'react';
import { ContactApp } from '../../shared/components/programs';
import { Desktop } from '../../shared/layouts/Desktop';
import { useNavigate } from 'react-router-dom';
import { DesktopIcon } from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/store/reducers/root';
import { closeApp, runApp } from '../../shared/store/reducers/appSlice';

const TalkToUs = () => {
  const navigate = useNavigate();
  const app = useSelector((state: RootState) => state.app);

  const talkToUsApp = app.apps[3];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(runApp(talkToUsApp));
    return () => {
      dispatch(closeApp(talkToUsApp));
    };
  }, [dispatch, talkToUsApp]);

  return (
    <Desktop>
      {app.apps.map(({ title, icon, type, action, x, y, hide }) => {
        return !hide && <DesktopIcon {...{ title, icon, type, action, x, y }} key={title} />;
      })}
      <ContactApp onClose={() => navigate('/')} />
    </Desktop>
  );
};

export default TalkToUs;
