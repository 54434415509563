import styled from 'styled-components';
import { AppWindow } from '../../../../AppWindow';

export const Container = styled.div`
  width: 100%;
`;

export const Row = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
`;

export const Icon = styled.img`
  margin: 1rem;
`;

export const StyledAppWindow = styled(AppWindow)`
  position: relative;
  max-width: 480px;
  max-height: 220px;
  overflow: inherit;
  left: initial;
  top: initial;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0 0 0;
`;
