import React, { useEffect } from 'react';
import { Container, Content, Title, TitleWrapper } from './styled';
import { useNavigate } from 'react-router-dom';

const BlueScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 2200);
    return () => {
      clearTimeout(timer);
    };
  }, [navigate]);
  return (
    <Container>
      <Content>
        <TitleWrapper>
          <Title>BOOO</Title>
        </TitleWrapper>
        <p>Don't be alarmed, it's not a real problem, you'll soon find yourself on the homepage</p>
        <p>
          A problem has been detected and Windows has been shut down to prevent damage to your
          computer.
        </p>
        <p>UNMOUNTABLE_BOOT_VOLUME</p>
        <p>
          If this is the first time you've seen this error screen, restart your computer. If this
          screen appears again, follow these steps:
        </p>
        <p>
          Check to make sure any new hardware or software is properly installed. If this is a new
          installation, ask your hardware or software manufacturer for any Windows updates you might
          need.
        </p>
        <p>
          {' '}
          If problems continue, disable or remove any newly installed hardware or software. Disable
          BIOS memory options such as caching or shadowing. If you need to use Safe Mode to remove
          or disable components, restart your computer, press F8 to select Advanced Startup Options,
          and then select Safe Mode.
        </p>
        <p>Technical Information:</p>
        <p>*** STOP: 0x000000ED (0x80F128D0, 0x0000009c, 0x00000000, 0x0000000)</p>

        <p> Collecting data for crash dump ...</p>
        <p> Initializing disk for crash dump ...</p>
      </Content>
    </Container>
  );
};

export default BlueScreen;
