import React, { FC } from 'react';
import { AppWindow } from '../../../AppWindow';
import AppIcon from '../../../../theme/assets/images/icons/games/spider.webp';
import { IFrame } from '../../../ui';
type Props = { onClose: () => void; x?: number; y?: number };

const SpiderSolitaire: FC<Props> = ({ onClose, x, y }) => {
  return (
    <AppWindow minHeight={445} title={'Spider Solitaire'} iconScr={AppIcon} {...{ onClose, x, y }}>
      <IFrame
        title={'spidersolitaire-game'}
        src={'https://www.squidbyte.com/games/spidersolitairewindowsxp/'}
      />
    </AppWindow>
  );
};

export default SpiderSolitaire;
