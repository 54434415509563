import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WindowPosition {
  x: number;
  y: number;
}

interface WindowState {
  position: WindowPosition;
}

const getInitialPosition = (): WindowPosition => {
  if (typeof window !== 'undefined') {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const windowWidth = 725;
    const windowHeight = 600;
    const x = (screenWidth - windowWidth) / 2;
    const y = (screenHeight - windowHeight) / 2;
    return { x, y };
  }
  return { x: 72, y: 72 };
};

const initialState: WindowState = {
  position: getInitialPosition(),
};

const windowSlice = createSlice({
  name: 'windows',
  initialState,
  reducers: {
    setPosition: (state, action: PayloadAction<WindowPosition>) => {
      state.position = action.payload;
    },
  },
});

export const { setPosition } = windowSlice.actions;
export default windowSlice.reducer;
