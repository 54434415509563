import React, { FC, useEffect, useState } from 'react';
import { IProjectViewer } from '../../../types/interfaces';
import {
  CasesList,
  ContentContainer,
  InfoContainer,
  InfoScrollView,
  NavigationContainer,
  PreviewWrapper,
  SocialsContainer,
  StyledContainer,
  StyledGallery,
  StyledScroll,
} from './styled';
import { Checkbox, MenuListItem, Separator, Toolbar } from 'react95';
import { ExampleView, Description, Preview } from './components/';
import { AppWindow } from '../../../index';
import { Example } from '../../../types/data';
import { findNearElements, getFirstExample } from './helpers';
import { Icon, SocialIcon } from '../../ui';
import { useNavigate, useParams } from 'react-router-dom';
import { Paths } from '../../../../router/routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/root';
import { caseIcons } from '../../../utils/cases';
import BackIcon from '../../../theme/assets/images/icons/backward.webp';

type Props = {
  data: IProjectViewer;
  onClose: () => void;
  x?: number;
  y?: number;
  fulldesk?: boolean;
};

const ProjectViewer: FC<Props> = ({ data, onClose, x, y, fulldesk }) => {
  const { examples, cases, name: title, description, icon, socials, descriptionImage } = data;
  const project = useSelector((state: RootState) => state.project);
  const { project: projectSlug } = useParams();
  const navigate = useNavigate();
  const [current, setCurrent] = useState<Example | undefined>();
  const [prev, setPrev] = useState<string | undefined>();
  const [next, setNext] = useState<string | undefined>();

  useEffect(() => {
    const nearElements = findNearElements(project.list, projectSlug);
    setNext(nearElements?.next?.slug);
    setPrev(nearElements?.prev?.slug);
    setCurrent(
      getFirstExample(
        examples?.map((item) => ({
          ...item,
          src: (item?.remoteUrl?.length > 0 && item?.remoteUrl) || item.media.url,
          type: (item?.remoteUrl?.length > 0 && 'video') || 'image',
        })) ?? [],
      ),
    );
    return;
  }, [examples, project.list, projectSlug]);

  return (
    <AppWindow fulldesk={false} iconScr={icon?.url} title={title} {...{ onClose, x, y }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <SocialsContainer>
          <MenuListItem size={'sm'} style={{ cursor: 'pointer' }}>
            <Icon
              size={24}
              src={BackIcon}
              onClick={() => navigate('/' + Paths.projects, { replace: true })}
            />
          </MenuListItem>
          {socials &&
            socials.map(({ slug, link }) => (
              <MenuListItem key={link} size={'sm'}>
                <SocialIcon social={slug} size={24} link={link} />
              </MenuListItem>
            ))}
        </SocialsContainer>
        <NavigationContainer>
          <MenuListItem
            style={{ fontWeight: 800 }}
            size={'sm'}
            onClick={() => prev && navigate('/' + Paths.projectViewer.replace(':project', prev))}
          >
            {'<'}
          </MenuListItem>
          <MenuListItem
            size={'sm'}
            style={{ fontWeight: 800 }}
            onClick={() => next && navigate('/' + Paths.projectViewer.replace(':project', next))}
          >
            {'>'}
          </MenuListItem>
        </NavigationContainer>
      </Toolbar>
      <Separator />
      <StyledContainer>
        <ContentContainer>
          <PreviewWrapper>
            {current && (
              <Preview
                src={current?.src}
                type={current.type}
                description={current?.description}
                onClick={() => {}}
                name={current.name}
              />
            )}
          </PreviewWrapper>
          <InfoContainer>
            <InfoScrollView>
              <CasesList label={'Cases'}>
                {cases &&
                  cases.map(({ name }) => (
                    <Checkbox
                      key={name}
                      label={`${caseIcons[name] || ''} ${name}`}
                      style={{ fontSize: '14px' }}
                      variant={'flat'}
                      checked
                    />
                  ))}
              </CasesList>
              {!!description?.value && description?.value.document.children.length > 1 && (
                <Description
                  description={description?.value}
                  descriptionImage={descriptionImage ? { url: descriptionImage.url } : undefined}
                />
              )}
            </InfoScrollView>
            <StyledScroll>
              <StyledGallery>
                {examples &&
                  examples.map((item, index) => (
                    <ExampleView
                      key={item.name + index}
                      {...{
                        ...item,
                        src: (item?.remoteUrl?.length > 0 && item?.remoteUrl) || item.media.url,
                        type: (item?.remoteUrl?.length > 0 && 'video') || 'image',
                      }}
                      onClick={() =>
                        setCurrent({
                          ...item,
                          src: (item?.remoteUrl?.length > 0 && item?.remoteUrl) || item.media.url,
                          type: (item?.remoteUrl?.length > 0 && 'video') || 'image',
                        })
                      }
                    />
                  ))}
              </StyledGallery>
            </StyledScroll>
          </InfoContainer>
        </ContentContainer>
      </StyledContainer>
    </AppWindow>
  );
};

export default ProjectViewer;
