import React from 'react';
import { Folder } from '../Folder';
import readmeIcon from '../../../theme/assets/images/icons/file_lines.png';

export const ReadmeFile = () => {
  return (
    <Folder key={'readme'} onClick={() => {}} variant={'drive'} icon={readmeIcon}>
      readme.txt
    </Folder>
  );
};
export default ReadmeFile;
