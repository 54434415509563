import React from 'react';
import { StructuredText, StructuredTextDocument } from 'react-datocms';
import {
  DescriptionImageContainer,
  DescriptionImg,
  DescriptionList,
  DescriptionTextContainer,
} from './styled';

interface ImageData {
  url?: string;
}

interface DescriptionProps {
  description?: StructuredTextDocument;
  descriptionImage?: ImageData;
}

const Description: React.FC<DescriptionProps> = ({ description, descriptionImage }) => {
  return (
    <DescriptionList label={'Description'}>
      <DescriptionTextContainer>
        {description && <StructuredText data={description} />}
      </DescriptionTextContainer>
      {descriptionImage && descriptionImage.url && (
        <DescriptionImageContainer>
          <DescriptionImg src={descriptionImage.url} alt={'Description Image'} />
        </DescriptionImageContainer>
      )}
    </DescriptionList>
  );
};

export default Description;
