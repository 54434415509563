import styled from 'styled-components';
import { AppWindow } from '../../AppWindow';
import { Button } from 'react95';

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 1rem 0;
  max-width: 640px;
`;
export const StyledText = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25em;
  white-space: pre-line;
  max-width: 400px;
`;
export const StyledContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledAppWindow = styled(AppWindow)`
  height: max-content;
  width: max-content;
  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
  }
`;

export const StyledMobileSubmit = styled(Button)`
  &&& {
    width: 120px;
  }
  font-size: 12px;
  margin: 0 4px 0 0;
`;
