import React, { useEffect, useState } from 'react';
import original from 'react95/dist/themes/original';
import { GlobalStyles } from './styledGlobal';
import { ThemeProvider } from 'styled-components';
import { RouterProvider } from 'react-router-dom';
import { rootRouter } from './router';
import { RootPortal, StartUpScreen } from './shared';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import './shared/i18n/i18n';
import ReduxProvider from './shared/store/provider';
import { SoundProvider } from './shared/contexts/SoundContext';

function App() {
  const [boot, setBoot] = useState(true);
  useEffect(() => {
    setBoot(false);
    setTimeout(() => setBoot(true), 4800);
    return () => {
      setBoot(false);
    };
  }, []);

  return (
    <>
      <GlobalStyles />
      {(boot && (
        <ReduxProvider>
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={original}>
              <SoundProvider>
                <RouterProvider router={rootRouter} />
                <RootPortal />
              </SoundProvider>
            </ThemeProvider>
          </DndProvider>
        </ReduxProvider>
      )) || <StartUpScreen />}
    </>
  );
}

export default App;
