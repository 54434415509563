import VideoIconPath from '../theme/assets/images/icons/file/file-video.webp';
import ImageIconPath from '../theme/assets/images/icons/file/file-image.webp';
import AudioIconPath from '../theme/assets/images/icons/file/file-audio.webp';
import DocumentIconPath from '../theme/assets/images/icons/file/file-document.webp';
import OtherIconPath from '../theme/assets/images/icons/file/file-lines.webp';

export const iconByFormat = (format: string) => {
  switch (format) {
    case 'picture':
      return ImageIconPath;
    case 'video':
      return VideoIconPath;
    case 'audio':
      return AudioIconPath;
    case 'document':
      return DocumentIconPath;
    default:
      return OtherIconPath;
  }
};
