import React from 'react';
import {
  AngelFish,
  AquariumContainer,
  Bubbles,
  ButterflyFish,
  FlounderFish,
  GuppyFish,
  JellyFish,
  MinnowFish,
  RedFish,
  SeahorsFish,
} from './styled';

const Screensaver = () => {
  return (
    <AquariumContainer>
      <Bubbles />
      <FlounderFish row={1} rtl />
      <ButterflyFish row={2} speed={'fast'} />
      <JellyFish row={3} rtl delay />
      <MinnowFish row={4} speed={'slow'} rtl delay />
      <AngelFish row={5} speed={'fast'} delay />
      <GuppyFish row={6} delay />
      <FlounderFish row={7} speed={'fast'} rtl />
      <RedFish row={8} />
      <JellyFish row={8} rtl speed={'slow'} />
      <SeahorsFish row={9} rtl delay />
      <SeahorsFish row={5} speed={'slow'} />
      <ButterflyFish row={9} delay />
    </AquariumContainer>
  );
};

export default Screensaver;
