import styled from 'styled-components';

export const SliderItem = styled.li`
  flex: 0 0 100%;
  list-style: none;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
`;

export const Content = styled.div`
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
`;
export const Text = styled.p`
  margin-bottom: 10px;
  line-height: 1.5;
  color: black;
  font-size: 1rem;
`;
