import styled from 'styled-components';

export const Section = styled.section`
  color: black;
  overflow: hidden;
  font-family: 'fsex300', monospace;
  height: 100%;
`;

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  text-align: center;
  height: 100%;
`;

export const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: calc(100% - 40px); /* Adjusting for title and navigation */
`;

export const SliderItems = styled.ul`
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
`;
