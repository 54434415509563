import styled from 'styled-components';
import { Icon } from '../Icon';

export const StyledDriveIcon = styled(Icon)`
  width: 100%;
  max-width: 48px;
  height: 48px;
  margin: 8px;
`;
export const IconContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem;
  cursor: pointer;
  user-select: none;
  width: 68px;
`;

export const SubIcon = styled(Icon)`
  position: absolute;
  width: 24px;
`;

export const StyledP = styled.p`
  font-size: 12px;
  text-align: center;
`;
