import { useContext, useCallback, useRef } from 'react';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import { findDOMNode } from 'react-dom';
import VideoPlayerContext from '../contexts/VideoPlayerContext';

const useVideoPlayer = () => {
  const context = useContext(VideoPlayerContext);

  if (!context) {
    throw new Error('useVideoPlayer must be used within a VideoPlayerProvider');
  }

  const { state, dispatch } = context;
  const playerRef = useRef<ReactPlayer>(null);

  const togglePlay = useCallback(() => {
    dispatch({ type: 'TOGGLE_PLAY' });
  }, [dispatch]);

  const toggleMute = useCallback(() => {
    dispatch({ type: 'TOGGLE_MUTE' });
  }, [dispatch]);

  const handleSeek = useCallback(
    (newValue: number) => {
      dispatch({ type: 'SET_SEEKING', payload: true });
      dispatch({ type: 'SET_PLAYED', payload: newValue / 100 });
    },
    [dispatch],
  );

  const handleSeekMouseUp = useCallback(
    (newValue: number) => {
      dispatch({ type: 'SET_SEEKING', payload: false });
      playerRef.current?.seekTo(newValue / 100, 'fraction');
    },
    [dispatch],
  );

  const handleProgress = useCallback(
    (progressState: { played: number }) => {
      if (!state.seeking) {
        dispatch({ type: 'SET_PLAYED', payload: progressState.played });
      }
    },
    [dispatch, state.seeking],
  );

  const handleDuration = useCallback(
    (duration: number) => {
      dispatch({ type: 'SET_DURATION', payload: duration });
    },
    [dispatch],
  );

  const handleFullscreen = useCallback(() => {
    if (playerRef.current && screenfull.isEnabled) {
      screenfull.request(findDOMNode(playerRef.current) as HTMLElement);
    }
  }, []);

  const handleRewind = useCallback((forward = false) => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(forward ? currentTime + 10 : currentTime - 10, 'seconds');
    }
  }, []);

  const formatTime = useCallback((seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  }, []);

  return {
    state,
    playerRef,
    togglePlay,
    toggleMute,
    handleSeek,
    handleSeekMouseUp,
    handleProgress,
    handleDuration,
    handleFullscreen,
    handleRewind,
    formatTime,
  };
};

export default useVideoPlayer;
