import styled from 'styled-components';
import { Frame, MenuList } from 'react95';
import { Icon } from '../../../../components/ui';

export const StyledFrame = styled(Frame)`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  bottom: 100%;
  width: max-content;
  height: max-content;
  padding: 4px;
`;
export const StyledMenuList = styled(MenuList)`
  border: none;
  box-shadow: none;
`;
export const StyledTitle = styled.span`
  text-align: left;
  width: 100%;
  margin: 0 0 0 8px;
`;
export const VerticalText = styled.div`
  text-align: end;
  background: linear-gradient(#c6c6c6, #050084 70%);
  writing-mode: vertical-lr;
  text-orientation: upright;
  color: gainsboro;
  height: 100%;
  width: max-content;
  max-height: 20rem;
  font-weight: 800;
  font-size: 16px;
  padding: 1rem 0.25rem;
`;
export const MenuIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`;
