import React, { FC, useMemo } from 'react';
import { Container, StyledToolbar, StyledVideoIcon } from './styled';
import ReactPlayer from 'react-player';
import Controls from './Controls';
import videoIcon from '../../../../../../theme/assets/images/icons/wm.webp';
import useVideoPlayer from '../../../../../../hooks/useVideoPlayer';
import { VideoPlayerProvider } from '../../../../../../contexts/VideoPlayerContext';

type Props = {
  name: string;
  url: string;
};

const VideoPlayer: FC<Props> = ({ name, url }) => {
  const {
    state,
    playerRef,
    togglePlay,
    toggleMute,
    handleSeek,
    handleSeekMouseUp,
    handleProgress,
    handleDuration,
    handleFullscreen,
    handleRewind,
    formatTime,
  } = useVideoPlayer();

  const formattedPlayedTime = useMemo(
    () => formatTime(state.played * state.videoDuration),
    [state.played, state.videoDuration, formatTime],
  );
  const formattedDuration = useMemo(
    () => formatTime(state.videoDuration),
    [state.videoDuration, formatTime],
  );

  return (
    <Container>
      <StyledToolbar>
        <StyledVideoIcon src={videoIcon} alt={name} /> {name}
      </StyledToolbar>
      <div style={{ flex: 1, position: 'relative', width: '100%' }}>
        <ReactPlayer
          width="100%"
          height="100%"
          ref={playerRef}
          url={url}
          playing={state.playing}
          muted={state.muted}
          volume={state.volume}
          onProgress={handleProgress}
          onDuration={handleDuration}
        />
      </div>
      <Controls
        slider={{ value: state.played * 100, max: 100 }}
        playing={state.playing}
        togglePlay={togglePlay}
        toggleFullscreen={handleFullscreen}
        toggleSound={toggleMute}
        rewind={handleRewind}
        onSeek={handleSeek}
        onSeekMouseUp={handleSeekMouseUp}
        mute={state.muted}
        currentTime={formattedPlayedTime}
        duration={formattedDuration}
      />
    </Container>
  );
};

const VideoPlayerWrapper: FC<Props> = ({ name, url }) => (
  <VideoPlayerProvider>
    <VideoPlayer name={name} url={url} />
  </VideoPlayerProvider>
);

export default VideoPlayerWrapper;
