import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/store/reducers/root';
import { useNavigate } from 'react-router-dom';
import { closeApp, runApp } from '../../shared/store/reducers/appSlice';
import { Desktop } from '../../shared/layouts/Desktop';
import { DesktopIcon } from '../../shared';
import { SpiderSolitaire } from '../../shared/components/programs';
import { caffeineOff, caffeineOn } from '../../shared/store/reducers/apps/screensaverSlice';

const OpenSolitaire = () => {
  const app = useSelector((state: RootState) => state.app);
  const thisApp = app.apps[7];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(caffeineOn());
    dispatch(runApp(thisApp));
    return () => {
      dispatch(closeApp(thisApp));
      dispatch(caffeineOff());
    };
  }, [dispatch, thisApp]);

  return (
    <Desktop>
      {app.apps.map(({ title, icon, type, action, x, y, hide }) => {
        return !hide && <DesktopIcon {...{ title, icon, type, action, x, y }} key={title} />;
      })}
      <SpiderSolitaire onClose={() => navigate('/')} />{' '}
    </Desktop>
  );
};

export default OpenSolitaire;
