import React, { createContext, useReducer, ReactNode } from 'react';

interface VideoPlayerState {
  playing: boolean;
  muted: boolean;
  volume: number;
  played: number;
  seeking: boolean;
  videoDuration: number;
}

const initialState: VideoPlayerState = {
  playing: true,
  muted: true,
  volume: 0.5,
  played: 0,
  seeking: false,
  videoDuration: 0,
};

type Action =
  | { type: 'TOGGLE_PLAY' }
  | { type: 'TOGGLE_MUTE' }
  | { type: 'SET_PLAYED'; payload: number }
  | { type: 'SET_SEEKING'; payload: boolean }
  | { type: 'SET_DURATION'; payload: number };

const reducer = (state: VideoPlayerState, action: Action): VideoPlayerState => {
  switch (action.type) {
    case 'TOGGLE_PLAY':
      return { ...state, playing: !state.playing };
    case 'TOGGLE_MUTE':
      return { ...state, muted: !state.muted };
    case 'SET_PLAYED':
      return { ...state, played: action.payload };
    case 'SET_SEEKING':
      return { ...state, seeking: action.payload };
    case 'SET_DURATION':
      return { ...state, videoDuration: action.payload };
    default:
      return state;
  }
};

interface VideoPlayerContextProps {
  state: VideoPlayerState;
  dispatch: React.Dispatch<Action>;
}

const VideoPlayerContext = createContext<VideoPlayerContextProps | undefined>(undefined);

export const VideoPlayerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <VideoPlayerContext.Provider value={{ state, dispatch }}>
      {children}
    </VideoPlayerContext.Provider>
  );
};

export default VideoPlayerContext;
