import React, { FC } from 'react';
import appIcon from '../../../../../theme/assets/images/icons/keys-lock.webp';
import appErrorIcon from '../../../../../theme/assets/images/icons/error.png';
import { Container, Icon, Row, StyledAppWindow, ButtonWrapper } from './styled';
import { Button, TextInput } from 'react95';

type Props = {
  onClose: () => void;
  pass: string;
  setPass: (value: string) => void;
  onPass?: () => void;
  waiting?: boolean;
  denied?: boolean;
  x?: number;
  y?: number;
};

const AccessRequest: FC<Props> = ({ onClose, onPass, setPass, pass, denied, x, y }) => {
  const title = 'Access Request';
  return (
    <StyledAppWindow isdraggable={false} {...{ title, y, x, onClose }}>
      <Row>
        <Icon width={86} height={86} src={(!denied && appIcon) || appErrorIcon} alt="icon" />
        <Container>
          {(!denied && (
            <div>
              Need access to the personal files? <br />
              Please write your password.
            </div>
          )) || (
            <div>
              Access denied. <br />
              Please rewrite your password.
            </div>
          )}
          <TextInput
            value={pass}
            onChange={({ target: { value } }) => setPass(value)}
            type={'password'}
            fullWidth
          />
          <ButtonWrapper>
            <Button onClick={onPass}>OK</Button>
          </ButtonWrapper>
        </Container>
      </Row>
    </StyledAppWindow>
  );
};

export default AccessRequest;
