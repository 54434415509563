import React, { FC, useRef, useState } from 'react';
import mailWizzardIcon from '../../../theme/assets/images/icons/mail-wizard.webp';
import {
  StyledAppWindow,
  StyledContainer,
  StyledMobileSubmit,
  StyledText,
  StyledTextWrapper,
} from './styled';
import ContactForm from './components/ContactForm/ContactForm';
import { useScopedTranslation } from '../../../hooks';
import { Button, Toolbar } from 'react95';
import { Help } from './components/Help';
type Props = { onClose: () => void; x?: number; y?: number };

const ContactApp: FC<Props> = ({ onClose, x, y }) => {
  const [submitted, setSubmitted] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };
  const submitRef = useRef<HTMLButtonElement>(null);
  const { t } = useScopedTranslation('talk_to_us');
  const MobileSubmit = (
    <StyledMobileSubmit
      onClick={() => {
        if (submitRef?.current) {
          submitRef.current.click();
        }
      }}
    >
      {t('form.submit')}
    </StyledMobileSubmit>
  );
  return (
    <StyledAppWindow
      minWidth={680}
      iconScr={mailWizzardIcon}
      title={t('title')}
      mobileActions={MobileSubmit}
      {...{ onClose, x, y }}
    >
      <Toolbar>
        <Button variant={'thin'} onClick={() => setHelpOpen(true)}>
          Help
        </Button>
      </Toolbar>
      {(submitted && (
        <StyledContainer>
          <h2>{t('success.header')}</h2>
          <div>{t('success.paragraph')}</div>
        </StyledContainer>
      )) || (
        <StyledContainer>
          <StyledTextWrapper>
            <StyledText>{t('text')}</StyledText>
          </StyledTextWrapper>
          <ContactForm handleSubmit={handleSubmit} submitRef={submitRef} />
          {helpOpen && <Help onClose={() => setHelpOpen(false)} x={50} y={50} key={'help'} />}
        </StyledContainer>
      )}
    </StyledAppWindow>
  );
};

export default ContactApp;
