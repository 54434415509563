import styled from 'styled-components';
import { Frame, ScrollView } from 'react95';

export const StyledFrame = styled(Frame)`
  min-width: 480px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  @media (max-width: 768px) {
    min-width: initial;
  }
`;
export const StyledNavBar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 0;
  font-size: 12px;
`;
export const FoldersScrollView = styled(ScrollView)`
  box-shadow: none;
  border: none;
  padding: 0;
  height: 480px;
  &:before {
    border: none;
    box-shadow: none;
  }
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const StyledStatus = styled(Frame)`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 4px;
`;
