import styled from 'styled-components';

export const Address = styled.address`
  width: max-content;
  height: max-content;
  padding: 1rem;
  p {
    margin: 0.5rem 0;
  }
  a {
    color: #0000ff;
    text-decoration: underline;
  }
`;
