import React from 'react';
import { Container, GradientSpinner, Logo, TextContainer } from './styled';
import { Icon } from '../../../ui';
import startLogo from '../../../../theme/assets/images/start-logo.webp';

const SplashScreen = () => {
  return (
    <Container>
      <Logo>
        <Icon src={startLogo} />
        <TextContainer>
          <i>MUDU</i>.<b>ME</b>
        </TextContainer>
      </Logo>
      <GradientSpinner />
    </Container>
  );
};

export default SplashScreen;
