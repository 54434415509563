import styled from 'styled-components';
import { GroupBox } from 'react95';

export const TitleContainer = styled(GroupBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 10px;
`;
export const PreviousArrow = styled.a`
  cursor: pointer;
  color: black;
  font-size: 1rem;
  text-decoration: none;
  margin-right: 10px;

  &:hover {
    opacity: 0.7;
  }
`;

export const NextArrow = styled.a`
  cursor: pointer;
  color: black;
  font-size: 1rem;
  text-decoration: none;
  margin-left: 10px;

  &:hover {
    opacity: 0.7;
  }
`;
export const Title = styled.h1`
  margin: 0 10px;
  font-size: 1rem;
`;
