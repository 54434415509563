import React, { useEffect } from 'react';
import { Desktop } from '../../shared/layouts/Desktop';
import { PartnersMC } from '../../shared/components/programs';
import { useApi } from '../../shared/hooks';
import { DesktopIcon, Loader } from '../../shared';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/store/reducers/root';
import { closeApp, runApp } from '../../shared/store/reducers/appSlice';
import {
  partnersLoad,
  partnersLoadFail,
  partnersLoadSuccess,
} from '../../shared/store/reducers/data/partnerSlice';

const Partners = () => {
  const navigate = useNavigate();
  const app = useSelector((state: RootState) => state.app);
  const partner = useSelector((state: RootState) => state.partner);

  const dispatch = useDispatch();
  const partnersApp = app.apps[5];

  useEffect(() => {
    dispatch(runApp(partnersApp));
    return () => {
      dispatch(closeApp(partnersApp));
    };
  }, [dispatch, partnersApp]);

  const [fetchData] = useApi('partners');

  useEffect(() => {
    dispatch(partnersLoad());
    fetchData()
      .then((r) => {
        dispatch(partnersLoadSuccess(r.data?.allPartners));
      })
      .catch((e) => {
        dispatch(partnersLoadFail(e));
      });
  }, [dispatch, fetchData]);
  return (
    <Desktop>
      {app.apps.map(({ title, icon, type, action, x, y, hide }) => {
        return !hide && <DesktopIcon {...{ title, icon, type, action, x, y }} key={title} />;
      })}
      {(!partner?.loading && partner?.participant?.length > 0 && (
        <>
          <PartnersMC allPartners={partner.participant} onClose={() => navigate('/')} />
        </>
      )) || <Loader title={'Partners'} onClose={() => navigate(-1)} />}
    </Desktop>
  );
};

export default Partners;
