import React, { FC } from 'react';
import { AppWindow } from '../../../AppWindow';
import AppIcon from '../../../../theme/assets/images/icons/games/minesweeper.png';
import { IFrame } from '../../../ui';

type Props = { onClose: () => void; x?: number; y?: number };

const Minesweeper: FC<Props> = ({ onClose, x, y }) => {
  return (
    <AppWindow title={'Minesweeper'} iconScr={AppIcon} {...{ onClose, x, y }}>
      <IFrame title={'minesweeper-game'} src={'https://mjamilfarooq.github.io/minesweeperbuild/'} />
    </AppWindow>
  );
};

export default Minesweeper;
