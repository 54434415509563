import { ScreensaverStateT } from '../../../types/states';
import { createSlice } from '@reduxjs/toolkit';

const initialState: ScreensaverStateT = {
  running: false,
  caffeine: false,
};

const screensaverSlice = createSlice({
  name: 'screensaver',
  initialState,
  reducers: {
    startTimer: (state) => {
      state.running = false;
    },
    endTimer: (state) => {
      state.running = true;
    },
    caffeineOn: (state) => {
      state.running = false;
      state.caffeine = true;
    },
    caffeineOff: (state) => {
      state.running = false;
      state.caffeine = false;
    },
  },
});
export const { startTimer, endTimer, caffeineOn, caffeineOff } = screensaverSlice.actions;
export default screensaverSlice.reducer;
