import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Paths } from './routes';
import {
  AboutUs,
  Home,
  OpenMinesweeper,
  OpenMyComputer,
  OpenMyComputerDriveC,
  OpenMyComputerDriveCCategory,
  OpenPaint,
  OpenSolitaire,
  Partners,
  Project,
  Projects,
  TalkToUs,
} from '../modules';
import { OpenGreetings } from '../modules/OpenGreetings';

export const rootRouter = createBrowserRouter([
  { path: Paths.aboutUs, element: <AboutUs /> },
  { path: Paths.desktop, element: <Home /> },
  { path: Paths.minesweeper, element: <OpenMinesweeper /> },
  { path: Paths.myComputer, element: <OpenMyComputer /> },
  { path: Paths.myComputerDriveC, element: <OpenMyComputerDriveC /> },
  { path: Paths.myComputerDriveCProjects, element: <OpenMyComputerDriveCCategory /> },
  { path: Paths.myComputerDriveCProjectsOpen, element: <Project /> },
  { path: Paths.paint, element: <OpenPaint /> },
  { path: Paths.partners, element: <Partners /> },
  { path: Paths.projects, element: <Projects /> },
  { path: Paths.projectViewer, element: <Project /> },
  { path: Paths.solitaire, element: <OpenSolitaire /> },
  { path: Paths.talkUs, element: <TalkToUs /> },
  { path: Paths.greetings, element: <OpenGreetings /> },
]);
