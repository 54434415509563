import { Slide } from '../types/interfaces';

export const slides: Slide[] = [
  {
    title: 'Welcome!',
    content: `At MUDU.ME, our mission is to revolutionize the digital landscape for our clients. Our work spans across various facets of the digital world, ensuring comprehensive solutions tailored to each business's unique needs. Here’s a detailed breakdown of what we do:`,
    sections: [
      {
        heading: 'Web Development & Design',
        items: [
          {
            title: 'Custom Websites',
            text: 'We create visually stunning and user-friendly websites that align with your brand’s identity. Our designs are not only aesthetically pleasing but also optimized for performance, ensuring fast load times and seamless navigation.',
          },
          {
            title: 'E-commerce Solutions',
            text: 'Our team develops robust e-commerce platforms that enhance user experience and drive sales. From user-friendly product catalogs to secure payment gateways, we handle all aspects of your online store.',
          },
        ],
      },
      {
        heading: 'Digital Marketing',
        items: [
          {
            title: 'SEO (Search Engine Optimization)',
            text: 'We optimize your website to rank higher on search engines, driving organic traffic and increasing visibility. Our SEO strategies are data-driven and tailored to meet your business goals.',
          },
          {
            title: 'SliderContent Marketing',
            text: 'Our content creators produce engaging and relevant content that resonates with your audience. Through blogs, articles, and multimedia content, we help establish your brand as a thought leader in your industry.',
          },
          {
            title: 'Social Media Management',
            text: 'We manage your social media presence across various platforms, creating and executing strategies that boost engagement and grow your follower base.',
          },
        ],
      },
      {
        heading: 'Branding & Identity',
        items: [
          {
            title: 'Brand Strategy',
            text: 'We develop comprehensive brand strategies that define your brand’s voice, vision, and values. Our goal is to create a consistent and compelling brand identity that stands out in the market.',
          },
          {
            title: 'Logo & Visual Design',
            text: 'Our designers craft unique logos and visual elements that represent your brand’s essence. We ensure that all visual assets are cohesive and reflective of your brand’s personality.',
          },
        ],
      },
      {
        heading: 'Data Analytics & Insights',
        items: [
          {
            title: 'Performance Tracking',
            text: 'We utilize advanced analytics tools to track and measure the performance of your digital campaigns. Our insights help refine strategies and maximize ROI.',
          },
          {
            title: 'Market Research',
            text: 'We conduct thorough market research to understand industry trends and consumer behavior, enabling us to craft strategies that are both innovative and effective.',
          },
        ],
      },
      {
        heading: 'Paid Advertising',
        items: [
          {
            title: 'PPC (Pay-Per-Click) Campaigns',
            text: 'Our PPC experts design and manage ad campaigns on platforms like Google Ads and social media. We focus on targeted advertising to reach the right audience and drive conversions.',
          },
          {
            title: 'Retargeting',
            text: 'We implement retargeting strategies to re-engage visitors who have previously interacted with your brand, converting them into loyal customers.',
          },
        ],
      },
    ],
  },
  {
    title: 'What do we do?',
    content: `Transforming your digital dreams into reality! At DigitalWave, we specialize in creating innovative and impactful digital solutions that elevate your brand and drive growth. From web design to comprehensive marketing strategies, we’ve got you covered. Let's make your digital presence unforgettable!`,
  },
  {
    title: 'Conceptual',
    content: `At MUDU.ME, we transform visions into vibrant digital realities. As a cutting-edge digital agency, we specialize in crafting bespoke solutions that drive engagement, boost brand visibility, and accelerate business growth. Our team of creative thinkers, tech enthusiasts, and marketing experts collaborate to deliver innovative strategies tailored to your unique needs. From stunning web designs and dynamic social media campaigns to data-driven SEO and beyond, we are dedicated to pushing the boundaries of digital excellence.
    Join us on this exciting journey and let's create something extraordinary together!`,
  },
];
