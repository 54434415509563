import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import { Language } from '../types/language';
import { appLocalStorage } from '../utils/appLocalStorage';
import { LANGUAGE_KEY } from '../constants';

const resources: Record<Language, { translation: object }> = {
  en: {
    translation: translationEN,
  },
};

const defaultLocale = (appLocalStorage.getItem(LANGUAGE_KEY) ?? Language.ENGLISH) as Language;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: false,
    lng: defaultLocale,
    fallbackLng: Language.ENGLISH,
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
