import styled from 'styled-components';
import { Frame } from 'react95';

const width = '57px';
const height = '32px';

export const Container = styled(Frame)`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: max-content;
  max-height: 98px;
  margin: 4px;
  padding: 8px;
  cursor: pointer;
  user-select: none;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: ${width};
  height: ${height};
`;

export const Img = styled.img`
  object-fit: cover;
  height: ${height};
  width: ${width};
`;
export const ThumbImg = styled.img`
  object-fit: contain;
  height: ${height};
`;
export const SubImg = styled.img`
  object-fit: cover;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 16px;
`;
export const VideoThumbnail = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${height};
  width: 100%;
`;

export const StyledName = styled.p`
  font-size: 10px;
  line-height: 1.5em;
  width: 100%;
  max-width: ${width};
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
