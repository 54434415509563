import { useState, useCallback } from 'react';
import { useApi } from '../useApi';
import { useDispatch } from 'react-redux';
import {
  personalLoad,
  personalLoadFail,
  personalLoadSuccess,
} from '../../store/reducers/data/personalSlice';

export const useAccessRequest = () => {
  const [showAccessRequest, setShowAccessRequest] = useState<boolean>(false);
  const [pass, setPass] = useState<string>('');
  const [denied, setDenied] = useState<boolean>(false);

  const [fetchData] = useApi('personal');
  const dispatch = useDispatch();

  const requestAccess = useCallback(
    (onSuccess: () => void) => {
      dispatch(personalLoad());
      fetchData({ variables: { limit: 1, find: pass } })
        .then((r) => {
          const data = r.data?.personal;
          if (data === null || data === undefined) {
            setDenied(true);
          } else {
            setDenied(false);
            setShowAccessRequest(false);
            onSuccess();
            dispatch(personalLoadSuccess(data));
          }
        })
        .catch((e) => {
          setDenied(true);
          dispatch(personalLoadFail(e));
        });
    },
    [dispatch, fetchData, pass],
  );

  return {
    showAccessRequest,
    setShowAccessRequest,
    pass,
    setPass,
    denied,
    setDenied,
    requestAccess,
  };
};
