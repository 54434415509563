import styled from 'styled-components';
import { Frame } from 'react95';

export const Subtitle = styled.h4`
  margin-bottom: 8px;
  color: black;
  font-size: 1.01rem;
  text-decoration: underline;
`;

export const AccordionSection = styled.div`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 30px;
  }
`;

export const AccordionHeader = styled(Frame)<{ isOpen: boolean }>`
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: ${({ isOpen }) => (isOpen ? 'italic' : 'normal')};
  color: ${({ isOpen }) => (isOpen ? '#000' : '#333')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #ccc;
  }
`;

export const AccordionIndicator = styled.span<{ isOpen: boolean }>`
  font-size: 1.2rem;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const AccordionContent = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? 'none' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  padding: ${({ isOpen }) => (isOpen ? '10px' : '0')};
  border-top: ${({ isOpen }) => (isOpen ? '1px solid #ccc' : 'none')};
`;

export const Text = styled.p`
  margin-bottom: 10px;
  line-height: 1.5;
  color: black;
  font-size: 1rem;
`;
