import styled from 'styled-components';
import { Slider, Toolbar } from 'react95';
import { Icon } from '../../../../../ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgb(198, 198, 198);
`;

export const StyledVideoIcon = styled(Icon)`
  width: 32px;
  height: 32px;
  margin: 0 4px 0 0;
`;

export const StyledToolbar = styled(Toolbar)`
  background-color: rgb(198, 198, 198);
  width: 100%;
  height: 36px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid rgb(132, 133, 132);
  z-index: 1;
`;

export const ControlsWrapper = styled(Toolbar)`
  background-color: rgb(198, 198, 198);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 36px;
  border-top: 2px solid rgb(254, 254, 254);
  z-index: 1;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledSlider = styled(Slider)`
  margin: 0 8px;
  flex: 0.85;
  @media (max-width: 768px) {
    flex: 1;
  }
`;

export const ControlBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const VideoDuration = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 4px 4px;
  margin-left: 6px;
  margin-right: 6px;
  white-space: nowrap;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(132, 133, 132) rgb(254, 254, 254) rgb(254, 254, 254) rgb(132, 133, 132);
`;
