import styled, { css } from 'styled-components';

type ITitle = {
  doubleClicked: boolean;
};
const draggedContainer = css`
  cursor: grabbing;
  border: 1px dot-dash gray;
`;
const droppedContainer = css`
  cursor: pointer;
  border: none;
`;

type IDnD = { x?: number; y?: number; isDragging?: boolean; isWhatsApp?: boolean };
export const StyledContainer = styled.div<IDnD>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: max-content;
  max-height: 76px;
  user-select: none;
  margin: 5px;
  position: ${({ isWhatsApp }) => (isWhatsApp ? 'absolute' : 'relative')};
  right: ${({ isWhatsApp }) => (isWhatsApp ? '.1em' : 'unset')};
  bottom: ${({ isWhatsApp }) => (isWhatsApp ? '3rem' : 'unset')};
  ${({ isDragging }) => (isDragging ? draggedContainer : droppedContainer)}
  pointer-events: ${({ isDragging, isWhatsApp }) => (isDragging && !isWhatsApp ? 'none' : 'auto')};
`;

export const StyledIconContainer = styled.div`
  position: relative;
  width: max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;
export const StyledShortcut = styled.img`
  position: absolute;
  width: 100%;
  max-width: 48px;
  height: auto;
  bottom: 0;
  left: 0;
`;
export const StyledTitle = styled.span`
  width: 100%;
  font-size: 12px;
  margin: 0.25em 0 0 0;
  text-wrap: none;
  text-align: center;
  line-height: 1em;
  color: whitesmoke;
  border: ${({ doubleClicked }: ITitle) =>
    (doubleClicked && 'white dashed 1px') || 'transparent dashed 1px'};
  height: ${({ doubleClicked }: ITitle) => (doubleClicked && 'max-content') || '24px'};
  user-select: ${({ doubleClicked }: ITitle) => (doubleClicked && 'text') || 'none'};
  text-overflow: ${({ doubleClicked }: ITitle) => (doubleClicked && 'break-word') || 'initial'};
  text-overflow: ${({ doubleClicked }: ITitle) => (doubleClicked && 'initial') || 'ellipsis'};
  overflow: ${({ doubleClicked }: ITitle) => (doubleClicked && 'initial') || 'hidden'};
  background-color: ${({ doubleClicked }: ITitle) => (doubleClicked && 'skyblue') || 'initial'};
  cursor: ${({ doubleClicked }: ITitle) => (doubleClicked && 'text') || 'pointer'};
`;
