import React, { FC, useEffect, useState } from 'react';
import { AppWindow } from '../../AppWindow';
import FolderApps from '../../../theme/assets/images/icons/folders/folder-apps.png';
import { useScopedTranslation } from '../../../hooks';
import { AppProps } from '../../../types/props';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../../../router/routes';
import { Folder } from '../../ui';
import { Project } from '../../../types/data';
import { FileExplorer } from '../../../layouts';
import { StyledStatus } from '../MyPuter/styled';

type Props = { projects: Project[]; onClose: () => void; x?: number; y?: number };

const ProjectsFolder: FC<Props> = ({ projects, onClose, x, y }) => {
  const { t } = useScopedTranslation('apps');
  const [icons, setIcons] = useState<AppProps[]>([]);
  const navigate = useNavigate();
  const [status, setStatus] = useState<string>('Noting');

  useEffect(() => {
    setIcons(
      projects.map((item) => {
        const action = () => {
          navigate('/' + Paths.projectViewer.replace(':project', item.slug));
        };
        return { title: item.name, type: 'app', action, icon: item?.icon?.url };
      }),
    );
    setStatus('Objects: ' + projects.length);
    return () => {
      setIcons([]);
    };
  }, [navigate, projects]);

  return (
    <AppWindow
      iconScr={FolderApps}
      title={t('Projects')}
      footer={<StyledStatus variant={'status'}>{status}</StyledStatus>}
      {...{ onClose, x, y }}
    >
      <FileExplorer>
        {icons.map((item) => (
          <Folder key={item.title} variant={'drive'} icon={item?.icon} onClick={item.action}>
            {item.title}
          </Folder>
        ))}
      </FileExplorer>
    </AppWindow>
  );
};

export default ProjectsFolder;
