import React, { useEffect, useRef, useState } from 'react';
import { Button, MenuListItem } from 'react95';
import startLogo from '../../../../theme/assets/images/start-logo.webp';
import { MenuIcon, StyledFrame, StyledMenuList, StyledTitle, VerticalText } from './styled';
import useSound from 'use-sound';
import FolderApps from '../../../../theme/assets/images/icons/folders/folder-apps.png';
import MyPuterImg from '../../../../theme/assets/images/icons/apps/my-puter.png';
import AboutUsImg from '../../../../theme/assets/images/icons/apps/about-us.png';
import MinesweeperImg from '../../../../theme/assets/images/icons/games/minesweeper.png';
import SpiderImg from '../../../../theme/assets/images/icons/games/spider.webp';
import PaintImg from '../../../../theme/assets/images/icons/games/paint.webp';
import MailWizzard from '../../../../theme/assets/images/icons/mail-wizard.webp';
import Partners from '../../../../theme/assets/images/icons/apps/partners.png';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../../../../router/routes';
import { useSoundContext } from '../../../../contexts/SoundContext';

const StartMenu = () => {
  const [open, setOpen] = useState(false);
  const { isSoundEnabled } = useSoundContext();
  const [play] = useSound('https://www.joshwcomeau.com/sounds/pop-down.mp3', {
    volume: isSoundEnabled ? 1 : 0,
  });

  const navigate = useNavigate();
  const startMenuRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (startMenuRef.current && !startMenuRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      window.addEventListener('mousedown', handleClickOutside);
    } else {
      window.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);
  const handleClick = (path: string) => {
    navigate(path);
    if (isSoundEnabled) play();
  };

  return (
    <>
      {open && (
        <StyledFrame shadow ref={startMenuRef}>
          <VerticalText>MUDU.ME</VerticalText>
          <StyledMenuList>
            <MenuListItem
              key={'MyComputer'}
              onClick={() => {
                handleClick('/my-computer');
              }}
            >
              <MenuIcon size={32} src={MyPuterImg} alt={'My Computer'} />
              <StyledTitle>My Computer</StyledTitle>
            </MenuListItem>
            <MenuListItem
              key={'tlktus'}
              onClick={() => {
                handleClick('/' + Paths.talkUs);
              }}
            >
              <MenuIcon size={32} src={MailWizzard} alt={'Talk to us'} />
              <StyledTitle>Talk to us</StyledTitle>
            </MenuListItem>
            <MenuListItem
              key={'parnt'}
              onClick={() => {
                handleClick('/' + Paths.partners);
              }}
            >
              <MenuIcon size={32} src={Partners} alt={'Partners'} />
              <StyledTitle>Partners</StyledTitle>
            </MenuListItem>
            <MenuListItem
              key={'prj'}
              onClick={() => {
                handleClick('/' + Paths.projects);
              }}
            >
              <MenuIcon size={32} src={FolderApps} alt={'projects'} />
              <StyledTitle>Projects</StyledTitle>
            </MenuListItem>
            <MenuListItem
              key={'aboutus'}
              onClick={() => {
                handleClick('/' + Paths.aboutUs);
              }}
            >
              <MenuIcon size={32} src={AboutUsImg} alt={'About us'} />
              <StyledTitle>About us</StyledTitle>
            </MenuListItem>
            <MenuListItem
              key={'minesweeper'}
              onClick={() => {
                handleClick('/' + Paths.minesweeper);
              }}
            >
              <MenuIcon size={32} src={MinesweeperImg} alt={'Minesweeper'} />
              <StyledTitle>Minesweeper</StyledTitle>
            </MenuListItem>
            <MenuListItem
              key={'solitaire'}
              onClick={() => {
                handleClick('/' + Paths.solitaire);
              }}
            >
              <MenuIcon size={32} src={SpiderImg} alt={'Solitaire'} />
              <StyledTitle>Spider Solitaire</StyledTitle>
            </MenuListItem>
            <MenuListItem
              key={'paint'}
              onClick={() => {
                handleClick('/' + Paths.paint);
              }}
            >
              <MenuIcon size={32} src={PaintImg} alt={'Paint'} />
              <StyledTitle>Paint</StyledTitle>
            </MenuListItem>
          </StyledMenuList>
        </StyledFrame>
      )}
      <Button style={{ fontWeight: 'bold' }} active={open} onClick={() => setOpen((prev) => !prev)}>
        <img src={startLogo} alt="react95 logo" style={{ height: '20px', marginRight: 4 }} />
        Start
      </Button>
    </>
  );
};

export default StartMenu;
