import styled from 'styled-components';
import { ScrollView } from 'react95';

export const StyledScroll = styled(ScrollView)`
  box-shadow: none;
  border: none;
  overflow-x: hidden;

  &:before {
    border: none;
    box-shadow: none;
  }
`;
