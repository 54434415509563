import styled from 'styled-components';
import { Button } from 'react95';
import { Icon } from '../../../../components/ui';

export const TaskArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 8px;
`;
export const TaskText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Task = styled(Button)`
  width: 100%;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  max-width: 16rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 8px;
`;

export const StyledIcon = styled(Icon)`
  width: 100%;
  max-width: 24px;
  height: auto;
  margin: 0 8px 0 0;
`;
