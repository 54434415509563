import styled from 'styled-components';
import { Button } from 'react95';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: blue;
  font-family: 'fsex300', monospace;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  padding: 1rem;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0.25rem;
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3rem;
  background-color: darkcyan;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 5rem;
  background-color: black;
`;

export const Console = styled.div`
  color: white;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
`;

export const TopMenu = styled(Button)`
  width: 100%;
  text-wrap: none;
  color: white;
  cursor: pointer;
  text-overflow: ellipsis;
  background-color: darkcyan;
  max-width: 10rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 8px;
`;
