import React, { FC, useEffect } from 'react';
import { Folder, ReadmeFile } from '../../../../ui';
import { useNavigate, useParams } from 'react-router-dom';
import { Paths } from '../../../../../../router/routes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers/root';
import { useApi } from '../../../../../hooks';
import {
  projectsLoad,
  projectsLoadFail,
  projectsLoadSuccess,
} from '../../../../../store/reducers/data/projectSlice';
import { Project } from '../../../../../types/data';

type Props = {
  onClick?: (value?: string) => void;
  setCategory?: (value?: string) => void;
  folders?: { name: string; icon: string; slug?: string }[];
  loading?: boolean;
};
const CategoryFolder: FC<Props> = ({ setCategory }) => {
  const navigate = useNavigate();

  const { slug: categorySlug } = useParams();
  const [fetchData] = useApi('projects');

  const dispatch = useDispatch();
  const projects = useSelector((state: RootState) => state.project);
  useEffect(() => {
    dispatch(projectsLoad());
    fetchData()
      .then((r) => {
        dispatch(projectsLoadSuccess(r.data?.allProjects));
      })
      .catch((e) => {
        dispatch(projectsLoadFail(e));
      });
  }, [dispatch, fetchData]);
  return (
    <>
      {!!projects.list &&
        projects.list
          .filter(
            (project: Project) =>
              project.categories &&
              project.categories.some((item: { slug: string }) => item.slug === categorySlug),
          )
          .map(({ name, icon, slug, categories }) => {
            return (
              <Folder
                key={name}
                variant={'drive'}
                icon={icon?.url}
                onClick={() => navigate('/' + Paths.projectViewer.replace(':project', slug))}
              >
                {name}
              </Folder>
            );
          })}
      <ReadmeFile />
    </>
  );
};

export default CategoryFolder;
