import styled from 'styled-components';
import { ScrollView } from 'react95';

export const FoldersScrollView = styled(ScrollView)`
  padding: 0;
  height: 100%;
  width: 100%;
`;
export const StyledFrame = styled.div`
  background-color: #fff !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  @media (max-width: 768px) {
    min-width: initial;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
  background-color: #fff !important;
`;
