import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import myPuterReducer from './apps/myPuterSlice';
import screensaverReducer from './apps/screensaverSlice';
import categoryReducer from './data/categorySlice';
import projectReducer from './data/projectSlice';
import partnerReducer from './data/partnerSlice';
import personalReducer from './data/personalSlice';
import windowReducer from './apps/appWindowSlice';

const root = combineReducers({
  app: appReducer,
  windows: windowReducer,
  categories: categoryReducer,
  myPuter: myPuterReducer,
  partner: partnerReducer,
  personal: personalReducer,
  project: projectReducer,
  screensaver: screensaverReducer,
});

export type RootState = ReturnType<typeof root>;

export default root;
