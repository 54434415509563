import { createSlice } from '@reduxjs/toolkit';
import { PartnerStateT } from '../../../types/states';

const initialState: PartnerStateT = {
  loading: false,
  participant: [],
  error: null,
};

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    partnersLoad: (state) => {
      state.loading = true;
    },
    partnersLoadSuccess: (state, action) => {
      state.loading = false;
      state.participant = action.payload;
    },
    partnersLoadFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { partnersLoad, partnersLoadFail, partnersLoadSuccess } = partnerSlice.actions;
export default partnerSlice.reducer;
