import React, { FC, useState, useEffect } from 'react';
import { AppWindow } from '../../AppWindow';
import AppIcon from '../../../theme/assets/images/icons/apps/partners.png';
import { SelectedInfo, BlueFrame } from './components';
import { Cell, Item, MCHeader, Pagination, PaginationButton, PaginationContainer } from './styled';
import { Partner } from '../../../types/data';
import McGroupBox from './components/MCGorupBox/MCGroupBox';

const TABS = {
  STUFF: 'staff',
  PARTNERS: 'partner',
  COLLABORATION: 'collaborator',
};

type Props = {
  allPartners: Partner[];
  onClose: () => void;
  x?: number;
  y?: number;
};

const PartnersMC: FC<Props> = ({ onClose, allPartners, x, y }) => {
  const [selectedTab, setSelectedTab] = useState(TABS.STUFF);
  const [selectedGroup, setSelectedGroup] = useState<Partner | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [itemsCount, setItemsCount] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const handleResize = () => {
      const isDesktop = window.innerWidth >= 768;
      setItemsPerPage(isDesktop ? 20 : 5);
    };

    handleResize(); // Initial call
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const countItems = () => {
      const counts: { [key: string]: number } = {};
      Object.values(TABS).forEach((tab) => {
        counts[tab] = allPartners.filter((participant) => participant.variant === tab).length;
      });
      setItemsCount(counts);
    };
    countItems();
  }, [allPartners]);

  const handleGroupClicked = (partner: Partner) => {
    setSelectedGroup(partner);
  };
  useEffect(() => {
    if (allPartners.length > 0) {
      const firstItem = allPartners.find((participant) => participant.variant === selectedTab);
      setSelectedGroup(firstItem || null);
    }
  }, [allPartners, selectedTab]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allPartners
    .filter((participant) => participant.variant === selectedTab)
    .slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(itemsCount[selectedTab] / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <AppWindow
      title={'MC'}
      x={0}
      y={0}
      disableScale={true}
      isdraggable={false}
      fulldesk={true}
      iconScr={AppIcon}
      onClose={onClose}
    >
      <BlueFrame selectedTab={selectedTab} onSelectTab={setSelectedTab}>
        <McGroupBox label={'List'}>
          <MCHeader>
            <Cell>{'<Fullname>'}</Cell>
            <Cell>{'<--->'}</Cell>
          </MCHeader>
          {currentItems.map((participant, index) => (
            <Item
              key={index}
              onClick={() => handleGroupClicked(participant)}
              selected={selectedGroup?.fullname === participant.fullname}
            >
              <Cell>{participant.fullname}</Cell>
              <Cell>--x</Cell>
            </Item>
          ))}
          <PaginationContainer>
            <PaginationButton onClick={handlePrevPage}>{'<'}</PaginationButton>
            <Pagination>
              {currentPage}
              {'/'}
              {totalPages}
            </Pagination>
            <PaginationButton onClick={handleNextPage}>{'>'}</PaginationButton>
          </PaginationContainer>
        </McGroupBox>
        <McGroupBox label={'Content'}>
          {selectedGroup && <SelectedInfo columnName={selectedTab} {...selectedGroup} />}
        </McGroupBox>
      </BlueFrame>
    </AppWindow>
  );
};

export default PartnersMC;
