import React, { useEffect, useState } from 'react';
import { Container } from './styled';
import { BootScreen, GlitchSplashScreen, SplashScreen } from './components';

const StartUpScreen = () => {
  const [boot, setBoot] = useState(true);
  const [glitch, setGlitch] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setBoot(false);
      localStorage.setItem('startupCompleted', 'true');
      setTimeout(() => setGlitch(true), 2400);
    }, 1600);
  }, []);
  return (
    <Container>
      {(boot && <BootScreen />) || (glitch && <GlitchSplashScreen />) || <SplashScreen />}
    </Container>
  );
};

export default StartUpScreen;
