import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.code<{ change?: boolean }>`
  width: 100%;
  height: 100%;
  background-color: black;
  font-family: ${({ change }) => (change && 'DOS_VGA_437') || 'fsex300'} 'DOS_VGA_437', monospace;
  color: white;
  max-width: 800px;
  max-height: 600px;
  overflow: hidden;
`;

export const BootStringS = styled(motion.div)<{ color?: string }>`
  font-size: 0.75rem;
  white-space: pre;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  ${({ color }) => `color: ${color};`}
`;
export const BootStringM = styled(motion.div)<{ color?: string }>`
  font-size: 1rem;
  white-space: pre;
  display: flex;
  flex-direction: row;
  ${({ color }) => `color: ${color};`}
`;

export const BootStringL = styled(motion.div)<{ color?: string }>`
  font-size: 2rem;
  white-space: pre;
  display: flex;
  flex-direction: row;
  ${({ color }) => `color: ${color};`}
`;

export const Logo = styled(motion.pre)`
  font-size: 0.5rem;
  font-weight: 600;
  color: mediumvioletred;
`;

export const DiffString = styled.span`
  font-size: 0.5rem;
  font-family: 'IBM_BIOS_2x', monospace;
  ${({ color }) => `color: ${color};`}
`;

export const Cursor = styled(motion.div)`
  font-size: 0.75rem;
  font-family: 'IBM_BIOS_2x', monospace;
  width: 8px;
  height: 16px;
  border: 2px solid white;
  background-color: white;
  pointer-events: none;
`;
