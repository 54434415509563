import React, { FC, useCallback } from 'react';
import { useDrag } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import { StyledContainer, StyledIconContainer, StyledShortcut, StyledTitle } from './styled';
import { Icon } from '../ui/Icon';
import Shortcut from '../../theme/assets/images/icons/shortcut.webp';
import { AppProps } from '../../types/props';
import { Paths } from '../../../router/routes';

const DesktopIcon: FC<AppProps> = ({ title, icon, type, x, y }) => {
  const navigate = useNavigate();

  const actionCallback = useCallback(
    (name: string) => {
      switch (name) {
        case 'My Computer':
          return () => {
            navigate(`/${Paths.myComputer}`);
          };
        case 'Projects':
          return () => {
            navigate(`/${Paths.projects}`);
          };
        case 'Talk to us':
          return () => {
            navigate(`/${Paths.talkUs}`);
          };
        case 'About us':
          return () => {
            navigate(`/${Paths.aboutUs}`);
          };
        case 'Partners':
          return () => {
            navigate(`/${Paths.partners}`);
          };
        case 'WhatsApp':
          return () => {
            window.open('http://wa.me/972584190296', '_blank', 'noreferrer');
          };
        default:
          return () => {
            console.warn(`No navigation action defined for ${name}`);
          };
      }
    },
    [navigate],
  );

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'AppIcon',
    item: { title, x, y },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const updateAction = actionCallback(title);
  const isWhatsApp = title.toLowerCase() === 'whatsapp';

  return (
    <StyledContainer ref={drag} {...{ x, y, isDragging, isWhatsApp }}>
      <StyledIconContainer onClick={updateAction}>
        {type === 'link' && <StyledShortcut src={Shortcut} />}
        <Icon src={icon} alt={title} />
      </StyledIconContainer>
      <StyledTitle doubleClicked={false}>{title}</StyledTitle>
    </StyledContainer>
  );
};

export default DesktopIcon;
