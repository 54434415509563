import styled from 'styled-components';
import { Frame } from 'react95';

export const StyledFrame = styled(Frame)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
`;
export const Name = styled.h2`
  font-size: 18px;
  font-weight: 600;
`;
export const Roles = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: row;
  flex-wrap: wrap;
  u {
    margin: 0 0.25rem;
  }
`;
export const Links = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const DataContainer = styled.div`
  display: grid;
  margin: 0 0 0 8px;
  grid-template-rows: 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  flex: 1;
`;
