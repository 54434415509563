import styled from 'styled-components';
import cloudsFront from '../../../../shared/theme/assets/images/clouds-front.png';
import { motion } from 'framer-motion';

export const MainContainer = styled.div`
  position: relative;
  background-image: url(https://d2kq0urxkarztv.cloudfront.net/63985d153db1670022651b5c/4101733/image-941dc888-9cba-4d53-8044-a486c42ae5bf.png?e=webp&nll=true);
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
  height: 100vh;
  @media (max-width: 768px) {
    overflow-y: visible;
    overflow-x: hidden;
  }
`;

export const CloudsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${cloudsFront});
  background-position: center;
  background-repeat: no-repeat;
  animation: wind 300s ease 1;
  overflow: visible;

  @keyframes wind {
    0% {
      background-position: 1000px 1000px;
    }
    100% {
      background-position: -1700px -1700px;
    }
  }

  @media (max-width: 768px) {
    background-size: cover;
    overflow: hidden;
  }
`;

export const AnimaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
`;

export const TextContainer = styled.div`
  display: none;
`;

export const FistBlock = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    min-height: auto;
    padding: 20px 0;
  }
`;

export const MiddleBlock = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (max-width: 768px) {
    min-height: auto;
    flex-direction: column;
  }
`;

export const EndBlock = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding: 20px;

  @media (max-width: 768px) {
    min-height: auto;
    padding: 20px 0;
  }
`;

export const EndRowOne = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
`;

export const EndColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ImgOne = styled(motion.img)`
  width: 100%;
  max-width: 400px;
  margin: 20px;

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (min-width: 1920px) {
    max-width: 600px;
  }

  @media (min-width: 2560px) {
    max-width: 800px;
  }
`;

export const ImgTwo = styled(motion.img)`
  width: 80%;
  max-width: 200px;
  margin-top: 2rem;

  @media (max-width: 768px) {
    width: 60%;
    transform: none !important;
  }

  @media (min-width: 1920px) {
    max-width: 300px;
  }

  @media (min-width: 2560px) {
    max-width: 400px;
  }
`;

export const ImgThree = styled(motion.img)`
  width: 80%;
  max-width: 300px;
  margin: 20px;

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (min-width: 1920px) {
    max-width: 450px;
  }

  @media (min-width: 2560px) {
    max-width: 600px;
  }
`;

export const ImgFour = styled(motion.img)`
  width: 80%;
  height: auto;
  max-width: 350px;
  display: block;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    width: 80%;
    max-width: none;
  }

  @media (min-width: 1920px) {
    max-width: 525px;
  }

  @media (min-width: 2560px) {
    max-width: 700px;
  }
`;

export const StyledMotionImage = styled(motion.img)`
  width: 80%;
  max-width: 200px;
  max-height: 200px;
  margin: 10px;

  @media (max-width: 768px) {
    width: 60%;
  }

  @media (min-width: 1920px) {
    max-width: 225px;
    width: 250px;
  }

  @media (min-width: 2560px) {
    max-width: 300px;
  }
`;
