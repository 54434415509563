import React from 'react';
import { Address } from './styled';

const CompanyAddress = () => {
  return (
    <Address>
      <p>מארק בורשטיין, 206199846</p>
      <p>יצחק טבנקין 213\8, רמת ממרא</p>
      <a href={'mailto:office@mudu.me'}>office@mudu.me</a>
    </Address>
  );
};

export default CompanyAddress;
