import React, { useState } from 'react';
import { Section, Container, SliderContainer, SliderItems } from './styled';
import { slides } from '../../../../utils/greetingsTabsContent';
import { ArrowNavigation } from './ArrowNavigation';
import { SliderContent } from './SliderContent';

const Slider: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSection, setActiveSection] = useState<number | null>(null);

  const goToPreviousSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
    setActiveSection(null);
  };

  const goToNextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
    setActiveSection(null);
  };

  const handleSectionClick = (index: number) => {
    setActiveSection(activeSection === index ? null : index);
  };

  return (
    <Section>
      <Container>
        <ArrowNavigation
          onPrevious={goToPreviousSlide}
          onNext={goToNextSlide}
          title={slides[activeIndex].title}
        />
        <SliderContainer>
          <SliderItems style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
            {slides.map((slide, index) => (
              <SliderContent
                key={index}
                slide={slide}
                activeSection={activeSection}
                onSectionClick={handleSectionClick}
              />
            ))}
          </SliderItems>
        </SliderContainer>
      </Container>
    </Section>
  );
};

export default Slider;
