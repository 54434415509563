import { MyPuterStateT } from '../../../types/states';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Category, Project } from '../../../types/data';

const initialState: MyPuterStateT = {
  loading: false,
  folders: [],
  error: null,
  files: [],
};

const myPuterSlice = createSlice({
  name: 'myPuter',
  initialState,
  reducers: {
    foldersLoad: (state) => {
      state.loading = true;
    },
    foldersLoadSuccess: (state, action: PayloadAction<Category[]>) => {
      state.loading = false;
      state.folders = action.payload;
    },
    foldersLoadFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    filesLoad: (state) => {
      state.loading = true;
    },
    filesLoadSuccess: (state, action: PayloadAction<Project[]>) => {
      state.loading = false;
      state.files = [
        ...state.files,
        ...action.payload.filter(
          (newFile) => !state.files.some((existingFile) => existingFile.id === newFile.id),
        ),
      ];
    },
  },
});
export const { foldersLoad, foldersLoadFail, foldersLoadSuccess, filesLoadSuccess, filesLoad } =
  myPuterSlice.actions;
export default myPuterSlice.reducer;
