import styled from 'styled-components';

export const Item = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 1.5rem;
  padding: 0 1rem;
  cursor: pointer;
  color: ${({ selected }) => (selected ? 'black' : 'white')};
  background-color: ${({ selected }) => (selected ? 'cadetblue' : 'transparent')};
`;

export const MCHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 1.5rem;
  padding: 0 1rem;
  color: white;
  margin: -1rem 0 1rem 0;
`;

export const Cell = styled.div`
  min-width: 4rem;
  padding: 0 0 0 0.5rem;
  letter-spacing: 0.1rem;
  text-align: center;

  :first-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: start;
    width: 60%;
    border-left: none;
  }

  @media (max-width: 638px) {
    font-size: 0.75rem;
    :first-child {
      text-align: start;
      width: 100%;
      border-left: none;
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
  color: white;
  @media (max-width: 638px) {
    font-size: 0.75rem;
  }
`;

export const PaginationButton = styled.span`
  min-width: 1rem;
  text-align: center;

  :hover {
    background-color: cadetblue;
  }
`;

export const Pagination = styled.div`
  min-width: 1rem;
  letter-spacing: 0.1rem;
  text-align: center;
  @media (max-width: 638px) {
    font-size: 0.75rem;
  }
`;
