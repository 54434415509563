import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  margin-bottom: 1rem;
`;

export const Number = styled.div`
  font-size: 1.25rem;
  width: 2em;
  height: 1em;
  color: white;
  letter-spacing: 0.1rem;
  line-height: 1.115em;
  text-align: right;
  font-weight: 100;
`;
export const Label = styled.div`
  width: 100%;
  height: 1.25rem;
  font-size: 1.25rem;
  background-color: darkcyan;
  text-align: center;
  letter-spacing: 0.1rem;
  line-height: 1.1em;
  padding: 0 0 0 2px;
`;
