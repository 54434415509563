import React, { useEffect } from 'react';
import { Desktop } from '../../shared/layouts/Desktop';
import { DesktopIcon } from '../../shared';
import { MyPuter } from '../../shared/components/programs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/store/reducers/root';
import { closeApp, runApp } from '../../shared/store/reducers/appSlice';

const OpenMyComputerDriveC = () => {
  const app = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();
  const myPuterApp = app.apps[0];
  useEffect(() => {
    dispatch(runApp(myPuterApp));
    return () => {
      dispatch(closeApp(myPuterApp));
    };
  }, [dispatch, myPuterApp]);

  const navigate = useNavigate();
  return (
    <Desktop>
      {app.apps.map(({ title, icon, type, action, x, y, hide }) => {
        return !hide && <DesktopIcon {...{ title, icon, type, action, x, y }} key={title} />;
      })}
      {app.opened.length > 0 && <MyPuter onClose={() => navigate('/')} step={'c'} />}
    </Desktop>
  );
};

export default OpenMyComputerDriveC;
