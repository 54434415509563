import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: blue;
  color: white;
  font-family: fsex300, serif;
  height: 100vh;
  width: 100vw;
  cursor: none;
`;
export const TitleWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 1rem 0;
`;
export const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  background-color: white;
  color: blue;
  line-height: 1.5em;
  padding: 0 1rem;
  width: max-content;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 15px;
    height: 100%;
  }
`;
