import React, { useState } from 'react';
import { IExample } from '../../../../../types/data';
import { Container, Img, StyledName, ThumbImg, VideoThumbnail, Wrapper } from './styled';
import thumbnailIcon from '../../../../../theme/assets/images/icons/file/file-wm.webp';

const ExampleView = ({ src, name, type, className, onClick }: IExample) => {
  const [variant, setVariant] = useState<'inside' | 'outside'>('outside');

  const content = (type === 'image' && <Img src={src} alt={name} />) || (
    <VideoThumbnail>
      <ThumbImg src={thumbnailIcon} alt={name} />
    </VideoThumbnail>
  );
  return (
    <Container
      onMouseEnter={() => setVariant('inside')}
      onMouseLeave={() => setVariant('outside')}
      variant={variant}
      className={className}
      onClick={onClick}
    >
      <Wrapper>{content}</Wrapper>
      <StyledName>{name}</StyledName>
    </Container>
  );
};

export default ExampleView;
