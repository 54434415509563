import React, { useEffect, useState } from 'react';
import { Desktop } from '../../shared/layouts/Desktop';
import { DesktopIcon } from '../../shared/components/DesktopIcon';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/store/reducers/root';
import { Paths } from '../../router/routes';
import { useNavigate } from 'react-router-dom';
import { setPosition } from '../../shared/store/reducers/apps/appWindowSlice';

const Home = () => {
  const app = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [showGreetings, setShowGreetings] = useState(false);
  useEffect(() => {
    const startupCompleted = localStorage.getItem('startupCompleted');
    if (startupCompleted === 'true') {
      setShowGreetings(true);
      localStorage.removeItem('startupCompleted');
    }
  }, []);

  useEffect(() => {
    if (showGreetings) {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const windowWidth = 725;
      const windowHeight = 600;
      const initialX = (screenWidth - windowWidth) / 2;
      const initialY = (screenHeight - windowHeight) / 2;

      dispatch(setPosition({ x: initialX, y: initialY }));
      navigate(Paths.greetings);
    }
  }, [showGreetings, navigate, dispatch]);

  return (
    <Desktop>
      {app.apps.map(({ title, icon, type, x, y, hide }) => {
        return (
          !hide && <DesktopIcon key={title} title={title} icon={icon} type={type} x={x} y={y} />
        );
      })}
    </Desktop>
  );
};

export default Home;
