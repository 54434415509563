import styled from 'styled-components';
import { SocialIcon } from '../../../../ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-height: 580px;
  border: 1px solid white;
  max-width: 500px;
  @media (max-width: 767px) {
    max-width: 400px;
    max-height: 400px;
  }
`;

export const Title = styled.h2`
  color: white;
  font-size: 16px;
  padding: 0.2rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  box-shadow: inset white 0 0 60px -30px;
  @media (max-width: 767px) {
    padding: 0;
    letter-spacing: 0;
    font-weight: 400;
    font-size: 14px;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  @media (max-width: 767px) {
    flex-direction: row;
  }
`;

export const MainSection = styled.div`
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  padding: 1rem;
  flex: 1;
`;

export const MainInfo = styled.div`
  max-height: 70px;
  overflow-x: hidden;
  overflow-y: auto;
  color: white;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    padding: 0.2rem;
  }

  &::-webkit-scrollbar {
    width: 5px;
    box-shadow: inset white 0 0 60px -30px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: white;
  }
`;

export const Image = styled.img`
  width: 200px;
  height: 250px;
  object-fit: cover;
  @media (max-width: 767px) {
    width: 120px;
    height: auto;
  }
  @media (max-width: 1024px) {
    max-width: 150px;
    height: auto;
  }
`;

export const BotWrapper = styled.div`
  display: flex;
`;

export const BotInfo = styled.div`
  border-top: 1px solid white;
  max-height: 100px;
  overflow-y: auto;
  padding: 1rem;
  flex: 3;

  :first-child {
    flex-grow: 2;
    border-right: 1px solid white;
  }

  &::-webkit-scrollbar {
    width: 5px;
    box-shadow: inset white 0 0 60px -30px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: white;
  }

  @media (max-width: 767px) {
    max-height: 60px;
    padding: 0.5rem;
  }
`;

export const BotInfoItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: start;
`;

export const TextElements = styled.span`
  color: white;
  letter-spacing: normal;
  padding: 0.3rem;
  @media (max-width: 767px) {
    font-size: 12px;
    font-weight: 400;
    padding: 0.2rem;
  }
`;

export const MCSocialIcon = styled(SocialIcon)`
  padding: 0.3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    padding: 0.2rem;
  }
`;
