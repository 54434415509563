import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Clock } from './Clock';
import { SystemTrayArea } from './SystemTrayArea';
import { Paths } from '../../../../../router/routes';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSoundContext } from '../../../../contexts/SoundContext';
import { Icon } from '../../../../components/ui/Icon';
import SoundOn from '../../../../theme/assets/images/icons/player/speaker.webp';
import SoundOff from '../../../../theme/assets/images/icons/player/speaker_off.webp';
import GreetingImg from '../../../../theme/assets/images/icons/apps/greetings.png';
import { Tooltip } from './Tooltip';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/root';

const SoundIcon = styled(Icon)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  position: relative;
`;

const SystemTray: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const app = useSelector((state: RootState) => state.app);
  const { isSoundEnabled, toggleSound } = useSoundContext();

  const [showTooltip, setShowTooltip] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const isGreetingsRun = app.opened.some((e) => e.title === 'Greetings');

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }
    if (location.pathname === '/' && !isGreetingsRun) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [location.pathname, isGreetingsRun, initialLoad]);

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const handleIconClick = () => {
    navigate('/' + Paths.greetings);
    setShowTooltip(false);
  };
  return (
    <SystemTrayArea>
      <IconWrapper>
        <Icon size={24} onClick={handleIconClick} src={GreetingImg} alt={'GreetingsIcon'} />
        {showTooltip && <Tooltip visible={showTooltip} onClose={handleTooltipClose} />}
      </IconWrapper>
      <SoundIcon
        size={24}
        onClick={toggleSound}
        src={isSoundEnabled ? SoundOn : SoundOff}
        alt={'soundIcon'}
      />
      <Clock />
    </SystemTrayArea>
  );
};

export default SystemTray;
