import React, { FC } from 'react';
import DriveImg from '../../../../../theme/assets/images/icons/drive.webp';
import NetworkDriveImg from '../../../../../theme/assets/images/icons/network-drive.webp';
import { AccessRequest } from '../AccessRequest';
import { Folder, Modal } from '../../../../ui';
import { useAccessRequest } from '../../../../../hooks/api/useAccessRequest';

type Props = {
  onClickC: () => void;
  onAccessGranted: () => void;
};

export const Drives: FC<Props> = ({ onClickC, onAccessGranted }) => {
  const { showAccessRequest, setShowAccessRequest, pass, setPass, denied, requestAccess } =
    useAccessRequest();

  return (
    <>
      <Folder icon={DriveImg} onClick={onClickC} variant={'drive'}>
        Drive (C:)
      </Folder>
      <Folder icon={NetworkDriveImg} variant={'drive'} onClick={() => setShowAccessRequest(true)}>
        Drive (D:)
      </Folder>
      <Modal show={showAccessRequest}>
        <AccessRequest
          pass={pass}
          setPass={setPass}
          denied={denied}
          onPass={() => requestAccess(onAccessGranted)}
          onClose={() => setShowAccessRequest(false)}
        />
      </Modal>
    </>
  );
};

export default Drives;
