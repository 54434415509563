import styled from 'styled-components';
import { Frame, GroupBox, ScrollView } from 'react95';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  max-height: 500px;
  overflow: auto;
  @media (max-width: 768px) {
    flex-direction: column;
    max-height: none;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  @media (max-width: 768px) {
    max-width: initial;
    flex-direction: column-reverse;
  }
`;

export const SocialsContainer = styled.ul`
  display: flex;
  flex-direction: row;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: hidden;
  height: 100%;
  text-align: right;
  background-color: #fff;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledExamplesFrame = styled(Frame)`
  display: flex;
  flex-direction: row;
  width: max-content;
  height: max-content;
  border: none;
  box-shadow: none;
`;
export const StyledGallery = styled.div`
  display: flex;
  overflow-x: auto;
  flex-direction: row;
  width: max-content;
  height: max-content;
  box-shadow: none;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  min-width: 250px;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: auto;
`;

export const StyledTitle = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.5rem;
`;

export const CasesList = styled(GroupBox)`
  min-height: 128px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const InfoScrollView = styled(ScrollView)`
  box-shadow: none;
  border: none;
  overflow-x: hidden;

  &:before {
    border: none;
    box-shadow: none;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledScroll = styled(ScrollView)`
  padding: 0;
`;

export const StyledStatus = styled(Frame)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-wrap: none;
  width: 100%;
  height: 100%;
  padding: 0 4px;
`;

export const NavigationContainer = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  user-select: none;
`;
