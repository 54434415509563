import React, { createContext, useContext, useState, useEffect, FC, ReactNode } from 'react';

type ScreenContextType = {
  width: number;
  height: number;
  scale: number;
};

type ScreenProviderProps = {
  children: ReactNode;
  initialWidth: number;
  initialHeight: number;
};

const ScreenContext = createContext<ScreenContextType | undefined>(undefined);

export const useScreen = () => {
  const context = useContext(ScreenContext);
  if (!context) {
    throw new Error('useScreen must be used within a ScreenProvider');
  }
  return context;
};

export const ScreenProvider: FC<ScreenProviderProps> = ({
  children,
  initialWidth,
  initialHeight,
}) => {
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const [scale, setScale] = useState(window.devicePixelRatio);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      setScale(window.devicePixelRatio);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ScreenContext.Provider value={{ width, height, scale }}>{children}</ScreenContext.Provider>
  );
};
