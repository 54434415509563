import React from 'react';
import styled from 'styled-components';

const StyledTray = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  cursor: pointer;
  margin: 0 12px;
  gap: 10px;
  @media (max-width: 768px) {
    margin: 0 6px;
    gap: 5px;
  }
`;

type INotificationArea = {
  children: React.ReactNode | null;
};
const SystemTray = ({ children }: INotificationArea) => {
  return <StyledTray>{children}</StyledTray>;
};

export default SystemTray;
