import styled from 'styled-components';

export const TooltipContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  bottom: 100%;
  left: auto;
  right: 0;
  transform: translateX(0);
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  background-color: #ffffe0;
  color: #000;
  text-align: left;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  z-index: 1000;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
  white-space: normal;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  font-family: 'fsex300', monospace;
  font-size: 14px;
  width: 200px;
  height: auto;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    right: 10px;
    margin-right: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #ffffe0 transparent transparent transparent;
  }
`;

export const CloseButton = styled.button`
  background: transparent;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  width: 22px;
  height: 22px;
  display: flex;
  color: black;
  border: 2px inset #e0e0e0;
  justify-content: center;
  align-items: center;
  line-height: 0;
  padding: 0;

  &:hover {
    background: #e0e0e0;
  }

  &:focus {
    outline: none;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
`;

export const Title = styled.div`
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;

  &::before {
    content: '⚠️';
  }
`;
