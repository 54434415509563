import { createGlobalStyle } from 'styled-components';
import { styleReset } from 'react95';

import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';
import fsex300 from './shared/theme/assets/fonts/FSEX300.ttf';
import PDOSVGA437Win from './shared/theme/assets/fonts/PerfectDOSVGA437Win.ttf';
import PxPlusIBMBIOS2x from './shared/theme/assets/fonts/PxPlus_IBM_BIOS-2x.ttf';

export const GlobalStyles = createGlobalStyle`
    ${styleReset}

    @font-face {
        font-family: 'ms_sans_serif';
        src: url('${ms_sans_serif}') format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'ms_sans_serif';
        src: url('${ms_sans_serif_bold}') format('woff2');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'fsex300';
        src: url('${fsex300}') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'DOS_VGA_437';
        src: url('${PDOSVGA437Win}') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'IBM_BIOS_2x';
        src: url('${PxPlusIBMBIOS2x}') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    body {
        font-family: 'ms_sans_serif', serif;
    }


    html, body {
        position: relative;
        height: 100dvh;
        overflow: hidden;
        box-sizing: border-box;
        @media (max-width: 768px) {
            overflow: auto;
        }
    }

    code {
        font-family: 'fsex300', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

`;
