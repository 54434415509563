import React from 'react';
import styled from 'styled-components';
import Placeholder from '../../../theme/assets/images/icons/file/file-executable-script.webp';

const StyledIcon = styled.img`
  width: ${({ size }: { size?: number }) => (size && size + 'px') || '100%'};
  max-width: 48px;
  height: ${({ size }: { size?: number }) => (size && size + 'px') || 'auto'};
  margin: 1px;
`;

type IIcon = {
  src?: string;
  alt?: string;
  onClick?: () => void;
  className?: string;
  size?: number;
};
const Icon = ({ src, onClick, alt, className, size }: IIcon) => (
  <StyledIcon onClick={onClick} src={src || Placeholder} {...{ alt, className, size }} />
);

export default Icon;
