import React, { FC, useEffect, useState } from 'react';
import { AppWindow } from '../AppWindow';
import { Hourglass, ProgressBar } from 'react95';
import styled from 'styled-components';

type Props = {
  title: string;
  onClose: () => void;
  x?: number;
  y?: number;
  step?: number;
};

const LoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const SmallAppWindow = styled(AppWindow)`
  width: 300px;
  height: 200px;

  @media (max-width: 768px) {
    width: 60%;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Loader: FC<Props> = ({ title, onClose, x, y }) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setPercent((previousPercent) => {
        if (previousPercent === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(previousPercent + diff, 100);
      });
    }, 750);
    return () => {
      setPercent(100);
      clearInterval(timer);
    };
  }, []);

  return (
    <SmallAppWindow isdraggable={false} title={title} onClose={onClose} x={x} y={y}>
      <LoaderContent>
        <Hourglass size={32} />
        <React.Fragment>Loading</React.Fragment>
        <ProgressBar variant={'tile'} value={Math.floor(percent)} style={{ marginTop: '20px' }} />
      </LoaderContent>
    </SmallAppWindow>
  );
};

export default Loader;
