import React, { FC, useEffect, useState } from 'react';
import { StyledNavBar, StyledStatus } from './styled';
import MyPuterImg from '../../../theme/assets/images/icons/apps/my-puter.png';
import { AppWindow } from '../../../index';
import { Icon } from '../../../components/ui';
import { useApi, useScopedTranslation } from '../../../hooks';
import { Button } from 'react95';
import { Drives } from './components/Drives';
import { DriveC } from './components/DriveC';
import BackIcon from '../../../theme/assets/images/icons/backward.webp';
import { CategoryFolder } from './components/CategoryFolder';
import { SelectOption } from 'react95/dist/Select/Select.types';
import { useParams } from 'react-router-dom';
import { FileExplorer } from '../../../layouts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/root';
import {
  categoriesLoad,
  categoriesLoadFail,
  categoriesLoadSuccess,
} from '../../../store/reducers/data/categorySlice';
import { PersonalFiles } from './components/PersonalFiles';
import PathSelector from './components/PathSelect/PathSelector';

type IPath = 'root' | 'c' | 'category';
type Props = { onClose: () => void; x?: number; y?: number; step?: IPath };

const defaultOptions = [
  { label: 'My computer', value: 'root' },
  { label: 'Drive (C:)', value: 'c' },
  { label: 'Drive (D:)', value: 'personal-files' },
];

const MyPuter: FC<Props> = ({ onClose, x, y, step = 'root' }) => {
  const { t } = useScopedTranslation('apps');
  const { slug: categorySlug } = useParams();
  const [options, setOptions] = useState<SelectOption<string>[]>(defaultOptions);
  const [folders, setFolders] = useState<[]>();
  const [status, setStatus] = useState<string>('Noting');
  const [currentAddr, setCurrentAddr] = useState<string | undefined>(
    (step === 'category' && categorySlug) || undefined,
  );

  const [path, setPath] = useState<IPath | string>(step);
  const dispatch = useDispatch();
  const { categories } = useSelector((state: RootState) => state.categories);
  const [fetchData] = useApi('categories');

  useEffect(() => {
    dispatch(categoriesLoad());
    fetchData()
      .then((r) => {
        dispatch(categoriesLoadSuccess(r.data?.allCategories));
      })
      .catch((e) => {
        dispatch(categoriesLoadFail(e));
      });
  }, [dispatch, fetchData]);
  useEffect(() => {
    if (categories) {
      setFolders(
        // @ts-ignore
        categories?.map((item: { icon?: { url?: string } }) => ({
          ...item,
          icon: item?.icon?.url,
        })),
      );
    }
  }, [categories]);
  useEffect(() => {
    if (path === 'category') {
      setOptions([...defaultOptions, { label: currentAddr, value: 'category' }]);
      setStatus(`Projects by category`);
    }
    if (path === 'root') {
      setStatus('Objects: 2');
    }
    if (path === 'c') {
      setStatus(`Objects: ${folders?.length}`);
    }
    return () => {};
  }, [currentAddr, folders?.length, path]);
  return (
    <AppWindow
      footer={<StyledStatus variant={'status'}>{status}</StyledStatus>}
      iconScr={MyPuterImg}
      title={t('my_puter')}
      {...{ onClose, x, y }}
    >
      <StyledNavBar>
        <PathSelector options={options} currentPath={path} onPathChange={setPath} />
        <Button
          onClick={() =>
            setPath((path === 'c' && 'root') || (path === 'category' && 'c') || 'root')
          }
          variant={'default'}
          style={{ height: '32px', width: '32px', padding: '1px 6px' }}
        >
          <Icon src={BackIcon} />
        </Button>
      </StyledNavBar>
      <FileExplorer>
        {path === 'root' && (
          <Drives
            onAccessGranted={() => {
              setCurrentAddr('D:/Personal');
              setPath('personal-files');
            }}
            onClickC={() => setPath('c')}
          />
        )}
        {path === 'c' && (
          <DriveC
            onClick={(value) => {
              setCurrentAddr(value);
              setPath('category');
            }}
            folders={folders}
            loading={!categories}
          />
        )}
        {path === 'category' && <CategoryFolder setCategory={setCurrentAddr} />}
        {path === 'personal-files' && <PersonalFiles />}
      </FileExplorer>
    </AppWindow>
  );
};

export default MyPuter;
