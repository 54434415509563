import styled from 'styled-components';
import { Slider, Toolbar } from 'react95';

export const Img = styled.img`
  object-fit: contain;
  max-height: 100%;
  width: 100%;
  height: 360px;
`;
export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  @media (max-width: 768px) {
    height: initial;
  }
`;
export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 8px 0 0;
`;

export const Controlbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Controls = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledSlider = styled(Slider)`
  position: absolute;
  background: rgb(198, 198, 198);
  bottom: 0;
  margin: 0;
`;
