import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppProps, ChangeAppPositionProps } from '../../types/props';
import { AppsStateT } from '../../types/states';
import { appsInitialState } from './initialStates';

const initialState: AppsStateT = {
  opened: [],
  apps: appsInitialState,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setOpened: (state, action: PayloadAction<AppProps[]>) => {
      state.opened = action.payload;
    },
    setApps: (state, action: PayloadAction<AppProps[]>) => {
      state.apps = action.payload;
    },
    runApp: (state, action: PayloadAction<AppProps>) => {
      const app = state.apps.find(({ title }) => title === action.payload.title);
      if (app) {
        state.opened = [...state.opened, app];
      }
    },
    closeApp: (state, action: PayloadAction<AppProps>) => {
      const app = state.apps.find(({ title }) => title === action.payload.title);
      if (app) {
        state.opened = [...state.opened.filter(({ title }) => title !== app.title)];
      }
    },
    changeAppPosition: (state, action: PayloadAction<ChangeAppPositionProps>) => {
      const app = state.apps.find(({ title }) => title === action.payload.title);
      if (app) {
        app.x = action.payload.x;
        app.y = action.payload.y;
      }
    },
  },
});

export const { runApp, closeApp, setOpened, setApps, changeAppPosition } = appSlice.actions;
export default appSlice.reducer;
