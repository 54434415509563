export const caseIcons: { [key: string]: string } = {
  Preview: '👀',
  'Last update': '🕒',
  'Art direction': '🎨',
  Identity: '🆔',
  'Pop-up production': '📦',
  Animation: '🎬',
  'Community management': '👥',
  'Marketing strategy': '📊',
  'Podcast & radio production': '🎙️',
  'Email marketing': '📧',
  'Influence marketing': '🌟',
  PR: '📰',
  Design: '🎨',
  '360 campaign': '🔄',
  'Creative campaign': '💡',
  '3D design': '🖼️',
  'Website development': '💻',
  'Massive fundraising': '💰',
  'Organization of infrastructure': '🏗️',
  Branding: '🔥',
  'Marketing research': '🔍',
  Sales: '💵',
  Copywriting: '✍️',
  PPC: '💸',
  'Content creation': '📝',
  'Social media marketing': '📱',
  'Backend infrastructure': '🖥️',
  'High-scale community management': '👥',
  'Video & photo production': '📷',
  'Overall strategy': '📈',
  'Event management': '🎟️',
  'Location management': '📍',
  'Staff management': '👔',
  'Video production': '📹',
};
