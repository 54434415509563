import React, { ReactElement } from 'react';
import { IconContainer, StyledContainer, StyledDriveIcon, StyledP, SubIcon } from './styled';
import folderIcon from '../../../theme/assets/images/icons/folders/folder.png';

type IFolder = {
  children: ReactElement | ReactElement[] | string;
  icon?: string;
  onHover?: () => void;
  onClick?: () => void;
  variant?: 'drive' | 'folder';
};
const Folder = ({ children, icon, onHover, onClick, variant = 'folder' }: IFolder) => {
  const baseIcon = (variant === 'drive' && icon) || folderIcon;
  return (
    <StyledContainer onMouseEnter={onHover} onClick={onClick}>
      <IconContainer>
        <StyledDriveIcon src={baseIcon} />
        {variant === 'folder' && <SubIcon src={icon} />}
      </IconContainer>
      <StyledP>{children}</StyledP>
    </StyledContainer>
  );
};

export default Folder;
