import styled, { css, keyframes } from 'styled-components';
import bubbles from '../../../shared/theme/assets/images/screensaver/bubbles_50.png';
import fishAngel from '../../../shared/theme/assets/images/screensaver/fish-angel.png';
import fishButterfly from '../../../shared/theme/assets/images/screensaver/fish-butterfly.png';
import fishFlounder from '../../../shared/theme/assets/images/screensaver/fish-flounder.png';
import fishGuppy from '../../../shared/theme/assets/images/screensaver/fish-guppy.png';
import fishJelly from '../../../shared/theme/assets/images/screensaver/fish-jelly.png';
import fishMinnow from '../../../shared/theme/assets/images/screensaver/fish-minnow.png';
import fishRed from '../../../shared/theme/assets/images/screensaver/fish-red.png';
import fishSeahors from '../../../shared/theme/assets/images/screensaver/fish-seahorse.png';
import seafloor from '../../../shared/theme/assets/images/screensaver/seafloor.jpg';

export const riseAnimation = keyframes`
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, -1800px);
  }
`;

export const reappearAnimation = keyframes`
  from {
    left: 20%;
  }
  to {
    left: 80%;
  }
`;

export const bubbleAltAnimation = keyframes`
  from {
    background-position: 0;
  }
  to {
    background-position: -100px;
  }
`;

export const toggleAnimation = keyframes`
  from {
    background-position: 0;
  }
  to {
    background-position: -290px;
  }
`;

export const AquariumContainer = styled.div`
  position: relative;
  background-color: black;
  background-image: url(${seafloor});
  background-position: bottom;
  background-repeat: repeat-x;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const Bubbles = styled.b`
  position: absolute;
  bottom: -60px;
  display: block;
  width: 50px;
  height: 56px;
  background-repeat: no-repeat;
  background-image: url(${bubbles});
  animation: ${riseAnimation} 13s linear infinite, ${reappearAnimation} 39s steps(3) infinite,
    ${bubbleAltAnimation} 0.2s steps(2) infinite;
`;

export const Fish = css<{ row: number; speed?: 'slow' | 'fast'; rtl?: boolean; delay?: boolean }>`
  position: absolute;
  width: 145px;
  height: 145px;
  top: -50%;
  left: -50%;
  animation: ${({ rtl }) => (rtl && RTLAnimation) || LTRAnimation}
      ${({ speed }) => (speed && { slow: '34s', fast: '16s' }[speed]) || '26s'}
      ${({ delay }) => (delay && '3s') || '0s'} linear infinite,
    ${toggleAnimation} 0.2s steps(2) infinite;
  ${({ row }) =>
    ({
      1: `
      top: 0%;
    `,
      2: `
      top: 9%;
    `,
      3: `
      top: 18%;
    `,
      4: `
      top: 27%;
    `,
      5: `
      top: 36%;
    `,
      6: `
      top: 45%;
    `,
      7: `
      top: 54%;
    `,
      8: `
      top: 63%;
    `,
      9: `
      top: 72%;
    `,
    }[row])}
`;

export const ButterflyFish = styled.div`
  background-image: url(${fishButterfly});
  ${Fish}
`;

export const GuppyFish = styled.div`
  background-image: url(${fishGuppy});
  ${Fish}
`;

export const FlounderFish = styled.div`
  background-image: url(${fishFlounder});
  ${Fish}
`;
export const JellyFish = styled.div`
  background-image: url(${fishJelly});
  ${Fish}
`;
export const MinnowFish = styled.div`
  background-image: url(${fishMinnow});
  ${Fish}
`;
export const AngelFish = styled.div`
  background-image: url(${fishAngel});
  ${Fish}
`;
export const RedFish = styled.div`
  background-image: url(${fishRed});
  ${Fish}
`;
export const SeahorsFish = styled.div`
  background-image: url(${fishSeahors});
  ${Fish}
`;

const RTLAnimation = keyframes`
  0% {
    transform: rotateY(180deg);
    left: 110%;
  }
  50% {
    left: -30%;
  }
  51% {
    transform: rotateY(180deg);
  }
  52% {
    transform: rotateY(0);
  }
  100% {
    left: 110%;
  }
`;

const LTRAnimation = keyframes`
  0% {
    left: -30%;
  }
  50% {
    left: 110%;
  }
  51% {
    transform: rotateY(0);
  }
  52% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(180deg);
    left: -30%;
  }
`;
