import styled from 'styled-components';
import { Checkbox, Select, TextInput } from 'react95';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 640px;
`;

export const StyledFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const StyledTextInput = styled(TextInput)`
  input {
    font-size: 18px;
  }
  margin: 0 0 0.5rem 0;
`;
export const StyledSelect = styled(Select)`
  input {
    font-size: 18px;
  }
  margin: 0 0 0.5rem 0;
`;
export const StyledCheckbox = styled(Checkbox)`
  font-size: 18px;
  margin: 0 0 0.5rem 0;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
