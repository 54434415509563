import React, { FC } from 'react';
import { AppWindow } from '../../../AppWindow';
import AppIcon from '../../../../theme/assets/images/icons/games/paint.webp';
import { IFrame } from '../../../ui';

type Props = { onClose: () => void; x?: number; y?: number };

const Paint: FC<Props> = ({ onClose, y, x }) => {
  return (
    <AppWindow title={'Paint'} iconScr={AppIcon} {...{ onClose }}>
      <IFrame title={'paint-game'} src={'https://jspaint.app/'} />
    </AppWindow>
  );
};

export default Paint;
