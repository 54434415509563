import React from 'react';
import { Frame } from 'react95';
import styled from 'styled-components';

const StyledFrame = styled(Frame)`
  padding: 4px;
`;

type INotificationArea = {
  children: React.ReactNode | null;
};
const NotificationArea = ({ children }: INotificationArea) => {
  return <StyledFrame variant={'well'}>{children}</StyledFrame>;
};

export default NotificationArea;
