import React, { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  ColumnWrapper,
  MainSection,
  MainInfo,
  Image,
  MCSocialIcon,
  Title,
  BotWrapper,
  BotInfo,
  BotInfoItem,
  TextElements,
} from './styled';
import { Role, Project, SocialLink } from '../../../../../types/data';
import { Paths } from '../../../../../../router/routes';

type Props = {
  fullname: string;
  avatar?: { url: string };
  roles?: Role[];
  skills?: string[];
  leadingProjects?: Project[];
  links?: SocialLink[];
  columnName?: string;
};

const SelectedInfo: FC<Props> = ({ fullname, avatar, links, roles, skills, leadingProjects }) => {
  const socialRef = useRef<HTMLAnchorElement>();
  const navigate = useNavigate();

  const handleProjectRef = (projectSlug: string) => {
    navigate(`/${Paths.projectViewer.replace(':project', projectSlug)}`, { replace: false });
  };

  return (
    <Container>
      <Title style={{ borderBottom: '1px solid white', fontSize: 18 }}>{fullname}</Title>
      <ColumnWrapper>
        <Image src={avatar?.url} alt={fullname} />
        <MainSection>
          <Title>{`Leading Projects`}</Title>
          <MainInfo>
            {leadingProjects &&
              leadingProjects.map((project, index) => (
                <React.Fragment key={project.slug}>
                  <TextElements
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => handleProjectRef(project.slug)}
                  >
                    {project.name}
                    {index !== leadingProjects.length - 1 && ', '}
                  </TextElements>
                </React.Fragment>
              ))}
          </MainInfo>
          <Title style={{ marginTop: '10px' }}>{`Role`}</Title>
          <MainInfo>
            {roles &&
              roles.map((item, index) => (
                <React.Fragment key={item.name}>
                  <TextElements>
                    {item.name}
                    {index !== roles.length - 1 && ', '}
                  </TextElements>
                </React.Fragment>
              ))}
          </MainInfo>
        </MainSection>
      </ColumnWrapper>
      <BotWrapper>
        <BotInfo>
          <Title>{`Links`}</Title>
          <BotInfoItem>
            {links &&
              links.map(({ slug, link }) => (
                <MCSocialIcon
                  // @ts-ignore
                  ref={socialRef}
                  key={slug}
                  social={slug}
                  link={link}
                  size={24}
                />
              ))}
          </BotInfoItem>
        </BotInfo>
        <BotInfo>
          <Title>{`Skills`}</Title>
          <BotInfoItem>
            <TextElements>{skills}</TextElements>
          </BotInfoItem>
        </BotInfo>
      </BotWrapper>
    </Container>
  );
};

export default SelectedInfo;
