import React from 'react';
import { VideoDuration, ControlBar, ControlsWrapper, StyledSlider } from './styled';
import { Button } from 'react95';
import speakerIcon from '../../../../../../theme/assets/images/icons/player/speaker.webp';
import speakerOffIcon from '../../../../../../theme/assets/images/icons/player/speaker_off.webp';
import playIcon from '../../../../../../theme/assets/images/icons/player/play.webp';
import pauseIcon from '../../../../../../theme/assets/images/icons/player/pause.webp';
import forwardIcon from '../../../../../../theme/assets/images/icons/player/forward.webp';
import backwardIcon from '../../../../../../theme/assets/images/icons/player/backward.webp';
import { Icon } from '../../../../../ui';

type ControlsProps = {
  slider: { value: number; max: number };
  playing: boolean;
  togglePlay: () => void;
  toggleFullscreen: () => void;
  toggleSound: () => void;
  rewind: (forward?: boolean) => void;
  onSeek: (value: number) => void;
  onSeekMouseUp: (value: number) => void;
  mute: boolean;
  currentTime?: string;
  duration?: string;
};

const Controls: React.FC<ControlsProps> = ({
  slider,
  playing,
  togglePlay,
  toggleFullscreen,
  toggleSound,
  rewind,
  onSeek,
  onSeekMouseUp,
  mute,
  duration,
  currentTime,
}) => {
  return (
    <ControlsWrapper>
      <ControlBar>
        <Button variant={'thin'} active={playing} onClick={togglePlay} square>
          <Icon alt={'play'} size={16} src={playing ? pauseIcon : playIcon} />
        </Button>
        <Button variant={'thin'} onClick={() => rewind(false)} square>
          <Icon alt={'rewind'} size={16} src={backwardIcon} />
        </Button>
        <Button variant={'thin'} onClick={() => rewind(true)} square>
          <Icon alt={'forward'} size={16} src={forwardIcon} />
        </Button>
        <StyledSlider
          value={slider.value}
          max={slider.max || 100}
          onChange={onSeek}
          onChangeCommitted={onSeekMouseUp}
        />
        <VideoDuration>
          {currentTime} / {duration}
        </VideoDuration>
        <Button variant={'thin'} onClick={toggleSound} square>
          <Icon alt={'sound'} size={18} src={mute ? speakerOffIcon : speakerIcon} />
        </Button>
      </ControlBar>
    </ControlsWrapper>
  );
};

export default Controls;
